import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CalculationTools from '../components/CalculationTools';
import CustomCardInfo from '../components/CustomCardInfo';
import DynamicIframe from '../components/DynamicIframe';
import LoadingComponent from '../components/LoadingComponent';
import PaymentPlanTable from '../components/PaymentPlanTable';
import useCreditCalculator from '../hooks/useCreditCalculator';
import { baseUrl } from '../utils/constants';
import { NumberFormatter } from '../utils/utils';

export default function CreditCalculationContainer() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [displayPaymentPlan, setDisplayPaymentPlan] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [iframeUrl, setIframeUrl] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [vade, setVade] = React.useState(searchParams.get('vade') || '0');
  const [creditAmount, setCreditAmount] = React.useState(searchParams.get('amount') || '0');
  const [interestRate, setInterestRate] = React.useState(searchParams.get('interestRate') || '0');
  const { monthlyPayment, totalPayment, paymentPlan } = useCreditCalculator(+vade, +creditAmount, +interestRate);
  const [pageInitialLoad, setPageInitialLoad] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState('');

  const onLoad = () => {
    setLoading(false);
  }

  const fetchIframe = async () => {
    try {
      setLoading(true);
      setIframeUrl('');
      const res = await fetch(`${baseUrl}/offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          loanType: 1,
          loanAmount: parseInt(searchParams.get('amount') || ''),
          maturity: parseInt(searchParams.get('vade') || '')
        })
      })
      const data = await res.json();
      if (data.iframe) {
        setIframeUrl(data.iframe);
        setErrorText('');
        setHasError(false);
      } else {
        setHasError(true);
        setErrorText('Bu kriterlere uygun kredi bulunamadı');
      }

    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchParams.get('loanType') === '2' && (!Boolean(searchParams.get('interestRate')) || searchParams.get('interestRate') === '0')) {
      setPageTitle(searchParams.get('vade') ? `${searchParams.get('vade')} ay vadeli ${NumberFormatter(searchParams.get('amount'))}₺ ${getLoanName(searchParams.get('loanType'))}` : '');
    } else {
      setPageTitle("");
    }
    setDisplayPaymentPlan(false);
    if (!pageInitialLoad) {
      setPageInitialLoad(true);
      return;
    }
    if (searchParams.get('interestRate') && searchParams.get('interestRate') !== '0') {
      setInterestRate(searchParams.get('interestRate') || '');

    } else {
      setInterestRate('0');
      if (searchParams.get('vade') && searchParams.get('amount')) {
        fetchIframe();
      } else {
        setIframeUrl('');
        setLoading(false);
      }
    }
    setVade(searchParams.get('vade') || '');
    setCreditAmount(searchParams.get('amount') || '');

  }, [searchParams.get('vade'), searchParams.get('amount'), searchParams.get('interestRate'), searchParams.get('loanType')]);

  useEffect(() => {
    if (searchParams.get('vade')) {
      setVade(searchParams.get('vade') || '');
    }

    if (searchParams.get('amount')) {
      setCreditAmount(searchParams.get('amount') || '');
      fetchIframe();
    }

    if (searchParams.get('interestRate')) {
      setInterestRate(searchParams.get('interestRate') || '');
    }
  }, []);

  const getLoanName = (value: string) => {
    if (value === '2') {
      return 'ihtiyaç kredileri';
    } else if (value === '3') {
      return 'taşıt kredileri';
    } else if (value === '4') {
      return 'konut kredileri';
    }
    return '';
  }

  return (
    <Box
      sx={{
        mt: 4,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: { xs: 2, sm: 4, md: 8, lg: 12 },
      }}
    >
      <Box
        sx={{
          maxWidth: 1600,
          width: '100%',
          mb: 5
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 30, md: 32, lg: 36, xl: 40 },
            fontWeight: '600',
          }}
        >
          Kredi Hesaplama
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 28, sm: 32, md: 48, lg: 60 },
            fontWeight: 600,
            textAlign: 'center',
            pb: { xs: 4, sm: 6, md: 8, lg: 10 }
          }}
        >
          {pageTitle}
        </Typography>
      </Box>
      <CalculationTools
        disabletitle
      />
      {
        +interestRate === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4,
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
              }}
            >

              {loading && (<Typography><LoadingComponent /></Typography>)}
              {hasError && (<Typography>{errorText}</Typography>)}
              {!loading && iframeUrl && (
                <DynamicIframe src={iframeUrl} onLoad={onLoad} />
              )}
            </Box>
          </Box>
        ) : (
          <>
            <CustomCardInfo
              totalPayment={+totalPayment}
              interestRate={+interestRate}
              monthlyPayment={+monthlyPayment}
              handleClick={() => setDisplayPaymentPlan(true)}
            />
            {displayPaymentPlan && (
              <PaymentPlanTable paymentPlan={paymentPlan} />
            )}</>
        )
      }
    </Box>
  );
}

import { Box, Button, Fab, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Blog, Role } from '../utils/types';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import BlogPostItem from '../components/BlogPostItem';

export default function AdminBlogListContainer() {

  const { token, user } = useContext(AuthContext);
  const nav = useNavigate();
  const { data: blogs, error } = useSWR<Blog[]>(`${baseUrl}/blog`, (url: string) => fetcher(url, token));

  return (
    <Box>
      <Grid container spacing={2}>
        {blogs?.map(blog => (
          <Grid item xs={12} md={6} lg={4}>
            <BlogPostItem
              author={blog.author}
              authorImage={blog.authorImage}
              coverImage={blog.coverImage}
              date={new Date(blog.createdAt).toLocaleDateString()}
              title={blog.title}
              onClick={() => { nav(`/admin/blog/${blog.id}/edit`) }}
            />
          </Grid>
        )
        )}
      </Grid>
      {user?.role === Role.SUPERADMIN && (
        <Fab
          onClick={() => nav('/admin/blog/new')}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            color: 'white',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  )
}

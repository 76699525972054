import { Home } from '@mui/icons-material';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import { List, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SideNavItem from './SideNavItem';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DvrIcon from '@mui/icons-material/Dvr';
import BookIcon from '@mui/icons-material/Book';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

type SideNavProps = {
  handleCloseDrawer?: () => void
}

export default function SideNav({ handleCloseDrawer }: SideNavProps = {}) {

  const loc = useLocation();

  return (
    <Paper
      sx={{
        width: 240,
        flexShrink: 0,
        minHeight: '100vh',
        borderRadius: 0
      }}
    >
      <List>
        <SideNavItem
          to="/admin"
          label="Dashboard"
          icon={<DashboardIcon />}
          selected={loc.pathname === '/admin'}
          handleCloseDrawer={handleCloseDrawer}
        />
        <SideNavItem
          to="/admin/users"
          label="Kullanıcılar"
          icon={<GroupIcon />}
          selected={loc.pathname === '/admin/users'}
          handleCloseDrawer={handleCloseDrawer}
        />
        <SideNavItem
          to="/admin/tanents"
          label="Tanent"
          icon={<SupportAgentIcon />}
          selected={loc.pathname === '/admin/tanents'}
          handleCloseDrawer={handleCloseDrawer}
        />
        <SideNavItem
          to="/admin/loan-rules"
          label="Kredi Kuralları"
          icon={<CreditScoreIcon />}
          selected={loc.pathname === '/admin/load-rules'}
          handleCloseDrawer={handleCloseDrawer}
        />
        <SideNavItem
          to="/admin/blogs"
          label="Blog"
          icon={<BookIcon />}
          selected={loc.pathname === '/admin/blogs'}
          handleCloseDrawer={handleCloseDrawer}
        />
         <SideNavItem
          to="/admin/blog-home-page"
          label="Blog Anasyafa"
          icon={<DashboardCustomizeIcon />}
          selected={loc.pathname === '/admin/blog-home-page'}
          handleCloseDrawer={handleCloseDrawer}
        />
        <SideNavItem
          to="/admin/categories"
          label="Kategoriler"
          icon={<CategoryIcon />}
          selected={loc.pathname === '/admin/categories'}
          handleCloseDrawer={handleCloseDrawer}
        />
        {/* <SideNavItem
          to="/admin/logs"
          label="Loglar"
          icon={<DvrIcon />}
          selected={loc.pathname === '/admin/logs'}
          handleCloseDrawer={handleCloseDrawer}
        /> */}
        <SideNavItem
          to="/"
          label="Site Anasayfa"
          icon={<Home />}
          selected={loc.pathname === '/'}
          handleCloseDrawer={handleCloseDrawer}
        />
      </List>
    </Paper>
  )
}

import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { refreshToken as refreshTkn } from '../services/authServices';

type GuardProps = {
  children: React.ReactNode;
}
const nonAuthRoutes = ['/login', '/register', '/login-register-main', '/kredi-basvuru', '/', '/kredi-listeleme', '/kredi-hesaplama', "/kredi-karti", '/sss', '/hakkimizda', '/blog', '/blog/:id', '/banka-musterisi-ol']

const nonAuthLoginRoutes = ['/login', '/register', '/login-register-main']

const visitorRoutes = ['/kredi-basvuru', '/', '/kredi-listeleme', '/kredi-hesaplama', "/kredi-karti", '/sss', '/hakkimizda', '/blog', '/blog/:id']

export default function Guard({ children }: GuardProps) {

  const { exp, refreshToken, logout, initialAuthDone, login } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);

  const nav = useNavigate();
  const loc = useLocation();

  const refresh = async () => {
    try {
      setRefreshing(true);
      const res = await refreshTkn(refreshToken!);
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('refreshToken', res.data.refreshToken)
      login(res.access_token, res.refresh_token);
      setRefreshing(false);
    } catch (err) {
      console.log(err);
      logout();
      setRefreshing(false);
    }
  }

  useEffect(() => {
    if (visitorRoutes.includes('/' + loc.pathname.split('/')[1])) return;

    if (!exp && !nonAuthRoutes.includes(loc.pathname)) {
      nav(`/login?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
    }

    if (exp && nonAuthLoginRoutes.includes(loc.pathname)) {
      const qs = new URLSearchParams(loc.search);
      let redirectUrl = qs.get('redirect');
      if (redirectUrl) {
        redirectUrl += '?' + qs.get('redirectQs');
      } else {
        redirectUrl = '/';
      }
      nav(redirectUrl, { replace: true });
    }

    if (exp) {
      console.log('exp var')
      if (exp < new Date()) {
        console.log('refresh edilmesi lazım')
        if (refreshToken) {
          console.log('refreshtoken var refresh edilecek')
          refresh();

        } else {
          console.log('refresh token yok')
          if (!nonAuthRoutes.includes(loc.pathname) && initialAuthDone) {
            console.log('nonroute pathlardan biri değil logoyr edilecek')
            nav(`/login?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
            logout!();
          }
        }
      }
    } else {
      console.log('exp yok')
      if (!nonAuthRoutes.includes(loc.pathname) && initialAuthDone) {
        console.log('nonroute pathlardan biri değil logoyr edilecek 2')
        nav(`/login?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
        logout!();
      }
    }

  }, [loc.pathname, exp])

  if (refreshing) return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
    </Box>
  )

  if (!exp) return null;

  return (
    <div>{children}</div>
  )
}

import React from 'react'
import { Blog } from '../utils/types'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

type BlogLayoutSelectProps = {
  blogs: Blog[]
  index: number
}

export default function BlogLayoutSelect({ blogs, index }: BlogLayoutSelectProps) {

  const [selectedBlog, setSelectedBlog] = React.useState<Blog | null>(null);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{index}. Blog</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedBlog ? selectedBlog.id : ''}
          label="Kategori"
          onChange={(e) => {
            const blog = blogs.find(blog => blog.id === e.target.value);
            blog && setSelectedBlog(blog);
          }}
        >
          {blogs.map((blog) => (
            <MenuItem key={`blog-${blog.id}`} value={blog.id}>{blog.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

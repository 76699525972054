import { Box, Typography } from '@mui/material'
import teamPic from '../assets/team-pic.png'
import React from 'react'

export default function Row4() {
  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: 1024,
          mx: 'auto',
          px: 2,
          py: 15,

        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            sx={{
              fontSize: 40,
              fontWeight: 600,
              width: '45%',
            }}
          >
            Ekibimiz
          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 400,
              lineHeight: '30px',
              width: '55%',
            }}
          >
            Cred ekibi, finansal sektörde deneyim sahibi uzmanlardan oluşur. Size en iyi hizmeti sunmak için buradayız ve finansal hedeflerinize ulaşmanız için yanınızdayız.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 10
          }}
        >
          <img
            src={teamPic}
            alt="team-pic"
            style={{
              width: '100%',
              maxWidth: 1024,
              height: 'auto'
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

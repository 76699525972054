import { Box, Typography } from '@mui/material'
import DynamicIframe from '../components/DynamicIframe'

export default function BecomeBankCustomer() {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 1600,
        mx: 'auto'
      }}
    >
      <Box
        sx={{
          my: 10
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 40, md: 48, lg: 52, xl: 60 },
            fontWeight: '600',
            textAlign: 'center',
          }}>
          Finans Dünyasına Yeni Bir Kapı: Şimdi Banka Hesabınızı Uzaktan Açın!
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 40, md: 48, lg: 52, xl: 60 },
            fontWeight: '400',
            textAlign: 'center',
          }}
        >
          Tüm Bankaların Hızlı Dijital Müşteri Olma Bağlantıları Burada!
        </Typography>
      </Box>
      <DynamicIframe
        src="https://pionr.koalay.com/uzaktan-musteri-edinimi/#/summary?qGuid=e1bf89fc-bf79-4d3e-a1a5-1aa4eb2d4e79"
        onLoad={() => {

        }}
      />
    </Box>
  )
}

import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const createTanent = async (token: string, values: any) => {
  const url = `${baseUrl}/tanent`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const getTanents = async (token: string) => {
  const url = `${baseUrl}/tanent`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const getTanent = async (id: number, token: string) => {
  const url = `${baseUrl}/tanent/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const updateTanent = async (id: number, token: string, values: any) => {
  const url = `${baseUrl}/tanent/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const deleteTanent = async (id: number, token: string) => {
  const url = `${baseUrl}/tanent/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}
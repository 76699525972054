import { Box } from '@mui/material'
import React from 'react'
import AdminBlogListContainer from '../containers/AdminBlogListContainer'

export default function AdminBlogList() {
  return (
    <Box
      sx={{
        width: '100%',
        padding: 2,
      }}
    >
      <AdminBlogListContainer />
    </Box>
  )
}


import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  trTR
} from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import DeleteDialog from '../components/DeleteDialog';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { LoanRule, LoanType, Status } from '../utils/types';
import { createLoanRule, deleteLoanRule, updateLoanRule } from '../services/LoanRulesServices';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}


function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = "yeni kural";
    setRows((oldRows) => [...oldRows, { id, loanType: '', minTerm: 0, maxTerm: 0, minAmount: 0, maxAmount: 0, status: Status.ACTIVE, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Yeni Kural Ekle
      </Button>
    </GridToolbarContainer>
  );
}

export default function AdminLoanRoles() {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null);
  const [deleting, setDeleting] = useState(false);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'loanType',
      type: 'singleSelect',
      headerName: 'Kredi Türü',
      width: 130,
      editable: true,
      getOptionValue: (value: any) => value.value,
      getOptionLabel: (value: any) => value.label,
      valueOptions: [{
        value: LoanType.PERSONAL_LOAN,
        label: 'İhtiyaç Kredisi'
      }, {
        value: LoanType.MORTGAGE_LOAN,
        label: 'Konut Kredisi'
      }, {
        value: LoanType.VEHICLE_LOAN,
        label: 'Taşıt Kredisi'
      }],
    },
    { field: 'minAmount', type: 'number', headerName: 'En Düşük Miktar', editable: true, width: 150 },
    { field: 'maxAmount', type: 'number', headerName: 'En Yüksek Miktar', editable: true, width: 130 },
    { field: 'minTerm', type: 'number', headerName: 'En Düşük Taksit', editable: true, width: 130 },
    { field: 'maxTerm', type: 'number', headerName: 'En Yüksek Taksit', editable: true, width: 130 },
    {
      field: 'status',
      type: 'singleSelect',
      headerName: 'Durum',
      width: 130,
      editable: true,
      valueOptions: [Status.ACTIVE, Status.INACTIVE],
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'İşlemler',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const { token } = React.useContext(AuthContext);

  const { data, error } = useSWR<LoanRule[]>(`${baseUrl}/loan-rule`, (url: string) => fetcher(url, token));

  useEffect(() => {
    if (data) {
      setRows(data.map((role) => {
        return {
          id: role.id,
          loanType: role.loanType,
          minTerm: role.minTerm,
          maxTerm: role.maxTerm,
          minAmount: role.minAmount,
          maxAmount: role.maxAmount,
          status: role.status,
        }
      }));
    }
  }, [data])

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = async (id: GridRowId) => {
    try {
      setDeleting(true);
      await deleteLoanRule(+id, token);
      const newData = rows.filter((row) => row.id !== id);
      console.log(newData)
      setRows(rows.filter((row) => row.id !== id));
      enqueueSnackbar('Kural silindi.', { variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Kural silinirken bir hata oluştu.', { variant: 'error' });
    } finally {
      setDeleteDialogOpen(false);
      setDeleting(false);
    }
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteDialogOpen(true);
    setSelectedRow(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.isNew) {
      try {
        delete newRow.isNew;
        delete newRow.id;
        const createdRow = await createLoanRule(token, newRow);
        setRows(rows.map((row) => (row.id === 'yeni kural' ? createdRow : row)));
        enqueueSnackbar('Kural oluşturuldu.', { variant: 'success' });
        return createdRow;
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Kural oluşturulurken bir hata oluştu.', { variant: 'error' });
      }
    } else {
      const updatedRow = { ...newRow, isNew: false };
      try {
        await updateLoanRule(newRow.id, token, newRow);
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        enqueueSnackbar('Kural güncellendi.', { variant: 'success' });
        return updatedRow;
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Kural güncellenirken bir hata oluştu.', { variant: 'error' });
      }
    }

  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Box
      sx={{
        pt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Typography
        variant='h4'
        sx={{
          mb: 2
        }}
      >
        Kurallar
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        style={{
          maxWidth: matches ? 'calc(100vw - 64px)' : 'calc(100vw - 290px)',
          height: '100%',
          margin: '16px'
        }}
      />
      <DeleteDialog
        title='Kuralı sil'
        description='Bu kuralı silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleDelete(selectedRow!)}
        deleting={deleting}
      />
    </Box>
  );
}

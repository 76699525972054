import { Box, Typography } from '@mui/material'
import filterCredit from '../assets/filter-credit.png';
import React from 'react'

export default function HomeAdvantage2() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        background: '#FDF690',
        borderRadius: '32px',
        height: '100%',
        p: { xs: 4, sm: 4, md: 8, lg: 8 }
      }}
    >
      <img
        src={filterCredit}
        alt="filter-credit"
        style={{
          maxWidth: '350px',
          aspectRatio: '1/1',
          objectFit: 'contain',
          width: '100%',
          margin: '0px auto',
        }}
      />
      <Typography
        fontWeight={600}
        fontSize={34}
      >
        Ödeme Planını Yap
      </Typography>
      <Typography
        fontSize={22}
      >
        Kredi profilini kullanarak sana özel tasarlanmış kredi seçeneklerini ve Cred’in hesaplama araçlarıyla sana özel ödeme planını hemen yap.
      </Typography>
    </Box>
  )
}

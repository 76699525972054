import { Box } from '@mui/material'
import file from '../assets/sozlesme/kullanici.pdf'
import PDFViewer from '../components/PDFViewer'

export default function UserAgreement() {

  return (
    <Box>
      <PDFViewer file={file} />
    </Box>
  )
}

import { Box, Card, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';

type CreditVariantItemProps = {
  title: string;
  icon: string;
  onClick: () => void;
}

export default function CreditVariantItem({ title, icon, onClick }: CreditVariantItemProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Paper
      elevation={isHovered ? 2 : 0}
      sx={{
        width: '100%',
        maxWidth: 210,
        height: '100%',
        p: 2,
        borderRadius: '16px',
        bgcolor: 'background.default',
        cursor: 'pointer', // Cursor'u işaretçi olarak ayarla
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => { onClick() }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pb:1.3,
          height: '100%'
        }}
      >
        <img width={75} height={75} src={icon} alt={title} />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Box>
    </Paper>
  );
}

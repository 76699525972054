import { Box } from '@mui/material'
import React from 'react'
import AboutUsHeader from '../components/AboutUsHeader'
import Row1 from '../components/Row1'
import Row2 from '../components/Row2'
import Row3 from '../components/Row3'
import Row4 from '../components/Row4'

export default function AboutUs() {
  return (
    <Box>
      <AboutUsHeader />
      <Box>
        <Row1 />
        <Row2 />
        <Row3 />
        <Row4 />
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import CreditListContainer from '../containers/CreditListContainer'

export default function CreditList() {

  return (
    <Box>
      <CreditListContainer />
    </Box>
  )
}

import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

type AuthLayoutProps = {
  image: React.ReactNode;
  children: React.ReactNode;
  bottomProgress?: React.ReactNode;
}

export default function AuthLayout({ image, children, bottomProgress }: AuthLayoutProps) {
  return (
    <Grid container>
      {/* Sol taraftaki resim */}
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            background: '#8377FB',
            height: '100vh', // Ekran yüksekliğine uyacak şekilde
          }}
        >
          <Box
            sx={{
              width: '100%',
              px: 8,
              pt: 8,
            }}
          >
            <Typography
              sx={{
                fontSize: 40,
                fontWeight: 600,
                color: 'white',
              }}
            >
              cred
            </Typography>
          </Box>
          {image}
        </Box>
      </Grid>
      {/* Sağ taraftaki içerik */}
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // Ekran yüksekliğine uyacak şekilde
            px: { xs: 4, sm: 8, md: 12, lg: 16 },
            position: 'relative',
          }}
        >
          {children}
          {bottomProgress && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
              }}
            >
              {bottomProgress}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

import React, { useContext, useEffect, useState } from 'react'
import { Category } from '../utils/types'
import { AuthContext } from '../contexts/AuthContext';
import { getCategories } from '../services/CategoryServices';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type CategorySelectProps = {
  value?: Category;
  onChange: (category: Category) => void;
}

export default function CategorySelect({ value, onChange }: CategorySelectProps) {

  const [categories, setCategories] = useState<Category[]>([]);
  const { token } = useContext(AuthContext);

  const fetchCategories = async () => {
    try {
      const res = await getCategories(token);
      setCategories(res);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCategories();
  }, [])

  const handleSelectChange = (e: any) => {
    const cat = categories.find(category => category.id === e.target.value);
    cat && onChange(cat);
  }

  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value ? value.id : ''}
          label="Kategori"
          onChange={handleSelectChange}
        >
          {categories.map((category) => (
            <MenuItem key={`category-${category.id}`} value={category.id}>{category.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import logoTiktok from '../assets/logo-tiktok.png'
import FooterSocial from './FooterSocial'
import Logo from './Logo'
import FooterCopyRight from './FooterCopyRight'
import FooterSectionItem from './FooterSectionItem'

export default function Footer() {

  const aboutUs = [
    {
      title: 'Cred Hakkında',
      link: '/hakkimizda'
    },
    {
      title: 'Aydınlatma Metni',
      link: '/aydinlatma-metni'
    },
    {
      title: 'Kullanıcı Sözleşmesi',
      link: '/kullanici-sozlesmesi'
    },
    {
      title: 'SSS',
      link: '/sss'
    },
    {
      title: 'İletişim',
      link: '/iletisim'
    },
  ];

  const products = [
    {
      title: 'İhtiyaç Kredisi',
      link: '/kredi-listeleme'
    },
    {
      title: 'Kredi Kartı',
      link: '/kredi-karti'
    },
    {
      title: 'Banka Müşterisi Ol',
      link: '/banka-musterisi-ol'
    },
    {
      title: 'Hesaplama Araçları',
      link: '/kredi-hesaplama'
    },
  ];

  const blog = [
    {
      title: 'Bütçeleme ve Harcama Yönetimi',
      link: '/blog'
    },
    {
      title: 'Kredi ve Borç Yönetimi',
      link: '/blog'
    },
    {
      title: 'Yatırım ve Portföy Yönetimi',
      link: '/blog'
    },
    {
      title: 'Emeklilik ve Gelecek Planlama',
      link: '/blog'
    },
  ]

  return (
    <Box
      sx={{
        px: [2, 4, 6, 10],
        pt: 10,
        pb: 4,
        borderTop: '1px solid #BDCEDF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: ['column-reverse', 'column-reverse', 'row'], // Column for small screens, row for larger screens
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          mb: 4
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row', 'row'], // Column for small screens, row for larger screens
            gap: 10,
          }}
        >
          <FooterSectionItem
            title="Hakkımızda"
            items={aboutUs}
          />
          <FooterSectionItem
            title="Ürünler"
            items={products}
          />
          <FooterSectionItem
            title="Blog"
            items={blog}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            alignItems: ['center', 'center', 'flex-end'], // Center horizontally for small screens, flex-start for larger screens
          }}
        >
          <Logo />
          <FooterSocial />
        </Box>
      </Box>
      <FooterCopyRight />
    </Box>
  )
}

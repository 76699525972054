import { Facebook, Instagram, Twitter, YouTube, LinkedIn } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import logoTiktok from '../assets/logo-tiktok.png'

export default function FooterSocial() {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: ['center', 'center', 'flex-start'], // Center horizontally for small screens, flex-start for larger screens
      }}
    >
      <IconButton
        onClick={() => {
          window.open('https://www.instagram.com/cred.global/', '_blank')
        }}
      >
        <Instagram sx={{ color: 'black' }} />
      </IconButton>
      <IconButton
        onClick={() => {
          window.open('https://twitter.com/CredGloball', '_blank')
        }}
      >
        <Twitter sx={{ color: 'black' }} />
      </IconButton>
      <IconButton
        onClick={() => {
          window.open('https://www.linkedin.com/company/credkredi', '_blank')
        }}
      >
        <LinkedIn sx={{ color: 'black' }} />
      </IconButton>
      {/* <IconButton>
        <Facebook sx={{ color: 'black' }} />
      </IconButton> */}
      <IconButton
        onClick={() => {
          window.open('https://www.tiktok.com/@cred.global', '_blank')
        }}
      >
        <img height={22} src={logoTiktok} alt="logo-tiktok" />
      </IconButton>
    </Box>
  )
}

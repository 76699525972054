export type User = {
  id?: number
  createdAt?: Date
  updatedAt?: Date
  email?: string
  name?: string
  role?: Role,
  status?: Status,
  phoneNumber?: string
  surname?: string
  nationalId?: string
  birthDate?: Date
}

export type Category = {
  id: number
  createdAt: Date
  updatedAt: Date
  name: string
  backgroundColor: string
  textColor: string
  priority: number
  status: Status
}

export type Blog = {
  id: number;
  title: string;
  content: string;
  coverImage: string;
  author: string;
  authorImage: string;
  category: Category;
  blogCategoryId: number;
  createdAt: Date;
}

export type LoanRule = {
  id: number
  createdAt: Date
  updatedAt: Date
  loanType: LoanType
  minAmount: number
  maxAmount: number
  minTerm: number
  maxTerm: number
  status: Status
}

export type Tanent = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  slug: string;
  status: Status;
}

export type Log = {

}

export enum LoanType {
  PERSONAL_LOAN = 'PERSONAL_LOAN',
  VEHICLE_LOAN = 'VEHICLE_LOAN',
  MORTGAGE_LOAN = 'MORTGAGE_LOAN'
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
  SUPERADMIN = 'SUPERADMIN'
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
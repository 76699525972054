import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import pic from '../../assets/slider-pic.svg';

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    color: '#DADEE2',
    opacity: 1,
    innerHeight: 8,
  },
  '& .MuiSlider-thumb': {
    height: 35,
    width: 35,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      content: `url(${pic})`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      objectFit: 'contain',
    },
  },
});

type ValueLabelComponentProps = {
  value: number;
  onChange: (value: number) => void;
  max: number;
  min: number;
  defaultValue: number;
  step: number;
};

export default function CustomSlider({ value, onChange, max, min, defaultValue, step }: ValueLabelComponentProps) {
  return (
    <PrettoSlider
      aria-label="pretto slider"
      defaultValue={defaultValue}
      onChange={(e, value) => onChange(value as number)}
      step={step}
      value={value}
      max={max}
      min={min}
    />
  );
}

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import greenHeaderRight from '../assets/green-header-right.svg';

export default function BlogHeader() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: '100%',
        background: '#8377FB',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={greenHeaderRight}
        alt="green-header-right"
        style={{
          position: 'absolute',
          display: matches ? 'block' : 'none',
          right: 0,
          top: -250,
          height: '800px', // Eklenen satır: Yükseklik 600px
        }}
      />
      <Typography
        sx={{
          fontSize: 60,
          fontWeight: 600,
          lineHeight: '72px',
          color: '#fff',
          py: 8,
        }}
      >
        Blog
      </Typography>
    </Box>
  );
}

import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import pic from '../assets/person1.svg'
import brandText from '../assets/home-brand-text.svg'
export default function CreditApplyHeader() {

  const nav = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: '100%',
        background: '#8377FB',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-between',
          maxWidth: 1600,
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: ['center', 'flex-start'],
            justifyContent: 'center',
            flexDirection: 'column',
            position: { sm: 'relative', md: 'absolute' },
            width: { sm: '100%', md: '60%', lg: '60%' },
            py: { xs: 2, sm: 4, md: 8, lg: 10 },
            px: { xs: 2, sm: 4, md: 8, lg: 12 },
          }}
        >
          <img
            src={brandText}
            alt="brand-text"
            style={{
              width: '100%',
              height: 'auto',
              marginBottom: 20,
              maxWidth: 413
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            mt: 8,
            mr: 20
          }}
        >
          <img
            src={pic}
            alt="credit-home-header-pic"
            style={{
              maxWidth: 1111,
              maxHeight: 600,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import CreditCardListContainer from '../containers/CreditCardListContainer'

export default function CreditCardList() {
  return (
    <Box>
      <CreditCardListContainer />
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'

export default function PersonBanner(props: { person: string }) {
  return (
    <Box
      sx={{
        background: '#BDF6F4',
        borderRadius: 4,
        height: '100%',
        p: 7
      }}
    >
      <img
        style={{
          width: '100%',
          height: '100%'
        }}
        src={props.person}
        alt="person"
      />
    </Box>
  )
}

import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import IndividualCreditCalculation from './IndividualCreditCalculation'
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { LoanRule, LoanType } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { AuthContext } from '../contexts/AuthContext';

export default function CreditApplyCalculation() {

  const [minAmaount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(0);
  const [minTerm, setMinTerm] = React.useState(0);
  const [maxTerm, setMaxTerm] = React.useState(0);
  const [sliderValue, setSliderValue] = React.useState(12);
  const [creditAmount, setCreditAmount] = React.useState('50000');
  const [vade, setVade] = React.useState('12');
  const [creditSliderValue, setCreditSliderValue] = React.useState(50000);
  const [interestRate, setInterestRate] = React.useState('0');
  const nav = useNavigate();
  const [value, setValue] = React.useState('2');
  const { token } = React.useContext(AuthContext);

  const { data: rules, error } = useSWR<LoanRule[]>(`${baseUrl}/loan-rule`, (url: string) => fetcher(url, token));


  useEffect(() => {
    if (rules) {
      if (value === '2') { // individual credit
        const individualCreditRules = rules.filter(rule => rule.loanType === LoanType.PERSONAL_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...individualCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...individualCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      } else if (value === '3') { // vehicle credit
        const vehicleCreditRules = rules.filter(rule => rule.loanType === LoanType.VEHICLE_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...vehicleCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...vehicleCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      } else if (value === '4') { // housing credit
        const housingCreditRules = rules.filter(rule => rule.loanType === LoanType.MORTGAGE_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...housingCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...housingCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      }
    }
  }, [value, rules])

  useEffect(() => {
    if (value === '2') {
      const individualCreditRules = rules?.filter(rule => rule.loanType === LoanType.PERSONAL_LOAN);
      const ruleData = individualCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    } else if (value === '3') {
      const vehicleCreditRules = rules?.filter(rule => rule.loanType === LoanType.VEHICLE_LOAN);
      const ruleData = vehicleCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    } else if (value === '4') {
      const housingCreditRules = rules?.filter(rule => rule.loanType === LoanType.MORTGAGE_LOAN);
      const ruleData = housingCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    }
  }, [creditAmount])

  const handleCalculate = (values: any) => {
    if (!Boolean(+values.interestRate)) {
      nav(`/kredi-listeleme?loanType=${value}&vade=${vade}&amount=${creditAmount}`);
    } else {
      nav(`/kredi-hesaplama?loanType=${value}&vade=${vade}&amount=${creditAmount}&interestRate=${values.interestRate}`);
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 4,
          backgroundColor: '#F3F5F7',
          borderRadius: 4,
          height: '100%',
          p: { xs: 2, sm: 4, md: 4, lg: 4 }
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 20, sm: 24, md: 32, lg: 40 },
          }}
        >
          İhtiyaç Kredisi
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IndividualCreditCalculation
            half
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            creditAmount={creditAmount}
            setCreditAmount={setCreditAmount}
            vade={vade}
            setVade={setVade}
            creditSliderValue={creditSliderValue}
            setCreditSliderValue={setCreditSliderValue}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            handleCalculate={handleCalculate}
            minAmount={minAmaount}
            maxAmount={maxAmount}
            minTerm={minTerm}
            maxTerm={maxTerm}
            interestEmptyError={false}
            disableInterestRate
          />
        </Box>
      </Box>
    </Box>
  )
}

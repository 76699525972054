import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Row1() {
  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: 1024,
          mx: 'auto',
          px: 2,
          py: 15,
          display: 'flex',
          gap:4,
          flexDirection: 'row',
          alignItems:'flex-start'
        }}
      >
        <Typography
          sx={{
            fontSize: 40,
            fontWeight: 600,
            width: '45%',
          }}
        >
          Hayallerinizi
          Gerçeğe Dönüştürün!
        </Typography>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 400,
            lineHeight: '30px',
            width: '55%',
          }}
        >
          Cred, finansal ihtiyaçlarınıza hızlı ve kolay bir çözüm sunan kredi karşılaştırma platformudur. Misyonumuz, size finansal özgürlüğün kapılarını aralayarak yaşamınızı daha iyi hale getirmenize yardımcı olmaktır.
        </Typography>
      </Box>
    </Box>
  )
}

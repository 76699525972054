import { Box, Typography } from '@mui/material'
import { ReactComponent as QuotesIcon } from '../assets/quotes.svg'
import React from 'react'

type CommentCardItemProps = {
  title: string;
  name: string;
  comment: string;
}

export default function CommentCardItem({ title, name, comment }: CommentCardItemProps) {
  return (
    <Box>
      <Box
        sx={{
          minWidth: { xs: 300, sm: 400, md: 500, lg: 700},
          background: 'white',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          height:'100%',
          gap: 3,
          p: 4
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }
          }}
        >
          <QuotesIcon />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 5,
          }}
        >
          <Typography
            sx={{
              fontSize: [16, 20, 24, 24],
            }}
          >
            {comment}
          </Typography>
          <Box>
            <Typography>
              {name}
            </Typography>
            <Typography
              sx={{
                color: '#4FA9A2'
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import BlogPostItem from './BlogPostItem'
import blogPost1 from '../assets/blog-post-1.png'
import blogPost2 from '../assets/blog-post-2.png'
import blogPost3 from '../assets/blog-post-3.png'
import { useNavigate } from 'react-router-dom'
import BlogCards from './BlogCards'
import BlogCardItem from './BlogCardItem'

type BlogPostsProps = {
  blogs?: any[];
};

export default function BlogPosts({ blogs }: BlogPostsProps) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const nav = useNavigate();

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <BlogPostItem
            author={blogs?.length > 0 ? blogs[0].author : "Mehmet Ali"}
            authorImage={blogs?.length > 0 ? blogs[0].authorImage : "https://avatars.githubusercontent.com/u/29109698?v=4"}
            coverImage={blogs?.length > 0 ? blogs[0].coverImage : blogPost1}
            date={blogs?.length > 0 ? blogs[0].date : "13 Aralık, 2020"}
            title={blogs?.length > 0 ? blogs[0].title : "Mali Kontrolü Elde Tutun: Bütçeleme Becerilerini Geliştirmenin Önemi"}
            onClick={() => { blogs?.length > 0 && nav(`/blog/${blogs[0].id}`) }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlogPostItem
            author="Mehmet Ali"
            authorImage="https://avatars.githubusercontent.com/u/29109698?v=4"
            coverImage={blogPost2}
            date="21 Aralık, 2020"
            title="Özgürlüğünüzü Kurtarın: Kredi Kartı Borcunu Yönetme Stratejileri"
            onClick={() => { }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <BlogPostItem
            author="Mehmet Ali"
            authorImage="https://avatars.githubusercontent.com/u/29109698?v=4"
            coverImage={blogPost3}
            isWide={matches}
            date="21 Aralık, 2020"
            title="Yarının Güvencesini Bugünden Planlayın: Emeklilik İçin Erken Planlamanın Önemi"
            onClick={() => { }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlogPostItem
            author="Mehmet Ali"
            authorImage="https://avatars.githubusercontent.com/u/29109698?v=4"
            date="13 Aralık, 2020"
            title="Beklenmedik Durumlara Karşı Hazır Olun: Acil Durum Fonu Oluşturmanın Adımları"
            onClick={() => { }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlogPostItem
            author="Mehmet Ali"
            authorImage="https://avatars.githubusercontent.com/u/29109698?v=4"
            date="21 Aralık, 2020"
            title="Bir Yatırımcı Olarak Başlangıç: Yatırım Temelleri ve Rehberi"
            onClick={() => { }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BlogCardItem
            blog={blogs[0]}
            key={0}
            onClick={() => { }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BlogCardItem
            blog={blogs[0]}
            key={0}
            onClick={() => { }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BlogCardItem
            blog={blogs[0]}
            key={0}
            onClick={() => { }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

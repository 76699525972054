import { Box, Grid, Typography } from '@mui/material';
import mobilePhone from '../assets/mobile-phone.png';
import { ReactComponent as Circle } from '../assets/draw-circle.svg';


function Metric({ text1, text2 }: any) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography
        sx={{
          fontSize: 40,
          fontWeight: 500,
        }}
      >
        {text1}
      </Typography>
      <Typography
        noWrap
        sx={{
          fontSize: 20,
          fontWeight: 400,
        }}
      >
        {text2}
      </Typography>
    </Box>
  );
}

function Madde({ text }: any) {
  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
    }}
  >
    <Box
      sx={{
        width: '5px',
        height: '5px',
        background: '#000',
        borderRadius: '50%',
        p: 0.3
      }}
    >
    </Box>
    <Typography
      sx={{
        fontSize: { xs: 16, sm: 20, md: 20, lg: 20 },
      }}
    >
      {text}
    </Typography>
  </Box>
}

export default function HomeAdvantage1() {
  return (
    <Box
      sx={{
        background: '#8FE8A4',
        borderRadius: '32px',
        px: 2
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            id="left-box"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              alignItems: { xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start' },
              gap: 4,
              width: '100%',
              py: { xs: 4, sm: 4, md: 8, lg: 8 },
              pl: { xs: 0, sm: 0, md: 8, lg: 8 },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 40, sm: 50, md: 60, lg: 60 },
                textAlign: ['center', 'center', 'left', 'left'],
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Neden
              {
                <Circle
                  style={{
                    marginTop: 25
                  }}
                />
              }
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
              }}
            >
              <Madde text="Online bir karşılaştırma platformu, kredi ve sigorta araştırmaların için kullanabileceğiniz bir kaynaktır." />
              <Madde text="İhtiyaçlarınıza en uygun ürüne en kolay şekilde ulaşmanızı sağlamak için bankaları karşılaştırır ve özel teklifler sunar." />
              <Madde text="Bağımsızdır, tüm banka ve sigorta şirketleriyle işbirliği yapar, kullanıcılarının çıkarlarını her zaman önde tutar." />
              <Madde text="Ücretsizdir." />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: { xs: 3, sm: 4, md: 6, lg: 6},
                ml: 3
              }}
            >
              <Metric text1="3 m" text2="kullanıcı" />
              <Metric text1="22" text2="banka" />
              <Metric text1="12" text2="sigorta şirketi" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            id="right-box"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={mobilePhone}
              alt="mobile-phone"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

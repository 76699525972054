import { Box, InputBaseComponentProps, SxProps, TextField } from '@mui/material'
import React from 'react'

type CustomTextFieldProps = {
  label: string
  value: string
  type?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps
  required?: boolean
  inputProps?: InputBaseComponentProps
}

export default function CustomTextField({ inputProps,required, type, label, value, onChange, sx }: CustomTextFieldProps) {
  return (
    <Box
      sx={{
        ...sx
      }}
    >
      {label && (
        <Box
          sx={{
            mb: 1,
            color: '#6B7280',
            fontSize: 14,
          }}
        >
          {label}
        </Box>
      )}
      <TextField
        variant='outlined'
        fullWidth
        type={type}
        required={required}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
        sx={{
          //remove border hover focus and outline background #F3F4F6
          width: '100%',
          height: '48px',
          backgroundColor: '#F3F4F6',
          borderColor: 'transparent',
          borderRadius: '8px',
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            borderRadius: '8px',
            backgroundColor: '#F3F4F6',
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#F3F4F6',
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-input': {
            borderRadius: '8px',
            backgroundColor: '#F3F4F6',
          },
          '& .MuiInputLabel-outlined': {
            borderRadius: '8px',
            color: '#74828F',
          },
          '& .MuiInputLabel-outlined.Mui-focused': {
            borderRadius: '8px',
            color: '#F3F4F6',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            borderColor: 'transparent',
          },
        }}
      />
    </Box>
  )
}

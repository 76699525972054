import { Box } from '@mui/material'
import { useContext } from 'react'
import useSWR from 'swr'
import BlogCategories from '../components/BlogCategories'
import BlogHeader from '../components/BlogHeader'
import BlogPosts from '../components/BlogPosts'
import { AuthContext } from '../contexts/AuthContext'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/global'
import { Blog as BlogType } from '../utils/types'
import LoadingComponent from '../components/LoadingComponent'

export default function Blog() {

  const { token } = useContext(AuthContext);

  const { data: blogs, error, isLoading } = useSWR<BlogType[]>(`${baseUrl}/blog`, (url: string) => fetcher(url, token));

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <BlogHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          width: '100%',
          maxWidth: '1600px',
          mx: 'auto',
          mt: 4,
          px: { xs: 2, md: 4 }
        }}
      >
        <Box
          sx={{
            minWidth: '250px !important',
            display: { xs: 'none', md: 'block' }
          }}
        >
          <BlogCategories />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <BlogPosts
            blogs={blogs}
          />
        </Box>
      </Box>
    </Box>
  )
}

import { Box, Grid } from '@mui/material'
import BannerText from '../components/BannerText'
import CreditApplyCalculation from '../components/CreditApplyCalculation'
import CreditApplyHeader from '../components/CreditApplyHeader'
import CustomerServices from '../components/CustomerServices'
import PersonBanner from '../components/PersonBanner'
import person from '../assets/person2.png';

export default function CreditApply() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        width: '100%',
      }}
    >
      <CreditApplyHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          alignItems: 'center',
          gap: 4,
          px: { xs: 2, sm: 4, md: 4, lg: 4 },
        }}
      >

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CreditApplyCalculation />
          </Grid>
          <Grid item xs={12} md={6}>
            <PersonBanner
              person={person}
            />
          </Grid>
        </Grid>
      </Box>
      <BannerText text="Hayallerini Erteleme • Sana özel kredi deneyimi • En iyi kredi fırsatları • Özel ödeme oranları • " />
      <CustomerServices />
    </Box>
  )
}

import { useState, useEffect } from 'react';

interface CreditCalculationResult {
  monthlyPayment: string;
  totalPayment: string;
  paymentPlan: PaymentPlanItem[];
}

export interface PaymentPlanItem {
  month: number;
  installment: number;
  principal: number;
  interest: number;
  kkdf: number;
  bsmv: number;
  balance: number;
}

const KKDF_RATE = 0.15;
const BSMV_RATE = 0.15;

const useCreditCalculator = (
  vade: number, 
  creditAmount: number, 
  interestRate: number
): CreditCalculationResult => {
  const [result, setResult] = useState<CreditCalculationResult>({
    monthlyPayment: '0',
    totalPayment: '0',
    paymentPlan: []
  });

  const calculateCredit = (): CreditCalculationResult => {
    const monthlyInterestRate = interestRate / 100;

    const totalMonthlyInterestRate =
      monthlyInterestRate +
      monthlyInterestRate * KKDF_RATE +
      monthlyInterestRate * BSMV_RATE;

    const monthlyPayment =
      creditAmount *
      totalMonthlyInterestRate /
      (1 - Math.pow(1 + totalMonthlyInterestRate, -vade));

    const totalPayment = monthlyPayment * vade;
    const paymentPlan = generatePaymentPlan(vade, creditAmount, monthlyInterestRate, monthlyPayment);

    return {
      monthlyPayment: monthlyPayment.toFixed(2),
      totalPayment: totalPayment.toFixed(2),
      paymentPlan
    };
  };

  const generatePaymentPlan = (vade: number, creditAmount: number, monthlyInterestRate: number, monthlyPayment: number): PaymentPlanItem[] => {
    let balance = creditAmount;
    let paymentPlan: PaymentPlanItem[] = [];

    for (let month = 1; month <= vade; month++) {
      const interest = balance * monthlyInterestRate;
      const kkdf = interest * KKDF_RATE;
      const bsmv = interest * BSMV_RATE;
      const principal = monthlyPayment - interest - kkdf - bsmv;
      balance -= principal;

      paymentPlan.push({
        month,
        installment: parseFloat(monthlyPayment.toFixed(2)),
        principal: parseFloat(principal.toFixed(2)),
        interest: parseFloat(interest.toFixed(2)),
        kkdf: parseFloat(kkdf.toFixed(2)),
        bsmv: parseFloat(bsmv.toFixed(2)),
        balance: parseFloat(balance.toFixed(2))
      });
    }

    return paymentPlan;
  };

  useEffect(() => {
    if (vade && creditAmount && interestRate) {
      const calculationResult = calculateCredit();
      setResult(calculationResult);
    }
  }, [vade, creditAmount, interestRate]);

  return result;
};

export default useCreditCalculator;

import { Box, Rating, Typography, useTheme } from '@mui/material'
import React from 'react'
import CircularButton from './common/CircularButton';
import { ArrowForward } from '@mui/icons-material';

type BankCreditCardItemProps = {
  bankPicture: string,
  totalPayment: string,
  interestRate: string,
  mouthlyPayment: string,
  bankRate: string,
  sponsored?: boolean
}

export const CardInfo = ({ title, value }: { title: string, value: string | number }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: ['center', 'flex-start'], // Responsive layout
        justifyContent: 'center',
        flex: 1,
        p: 2,
      }}
    >
      <Typography
        sx={{
          color: theme.palette.text.secondary,
          fontSize: [16, 18], // Responsive font size
          fontWeight: 600
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.text.primary,
          fontSize: [24, 30], // Responsive font size
          fontWeight: 600
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default function BankCreditCardItem({ bankPicture, totalPayment, interestRate, mouthlyPayment, bankRate, sponsored }: BankCreditCardItemProps) {

  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        borderRadius: 4,
        width: '100%',
        display: 'flex',
        flexDirection: ['column', 'row'], // Responsive layout
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2, 4], // Responsive padding
        position: 'relative',
      }}
    >
      {sponsored && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: '#01B1B4',
            borderTopLeftRadius: 32,
            borderBottomRightRadius: 32,
            px: 2, // Responsive padding
            color: 'white',
          }}
        >
          <Typography>
            Sponsorlu
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          right: 16 // Responsive positioning
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Typography
            fontSize={[20, 28]} // Responsive font size
          >
            4.8
          </Typography>
          <Box>
            <Rating
              sx={{
                color: theme.palette.primary.main,
                fontSize: ['18px', '24px'], // Responsive font size
              }}
              name="read-only"
              value={4.8}
              readOnly
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <img
          style={{
            width: '100%',
            height: '100%'
          }}
          src={bankPicture}
          alt="bank"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'], // Responsive layout
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          flex: 1,
          p: [2, 4], // Responsive padding
        }}
      >
        <CardInfo title="Toplam Ödeme" value={totalPayment} />
        <CardInfo title="Faiz Oranı" value={interestRate} />
        <CardInfo title="Aylık Ödeme" value={mouthlyPayment} />
      </Box>
      <Box>
        <CircularButton
          label="Hemen Başvur"
          color={theme.palette.secondary.main}
          textColor={theme.palette.primary.main}
          postIcon={<ArrowForward />}
          onClick={() => { }}
          sx={{
            px: [2, 3], // Responsive padding
            py: [1, 2], // Responsive padding
            fontSize: [16, 18], // Responsive font size
          }}
        />
      </Box>
    </Box>
  )
}

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react'

type FaqItemProps = {
  faq: {
    q: string;
    a: string;
  }
}

export default function FaqItem({ faq }: FaqItemProps) {

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={handleExpandClick}
      sx={{
        width: '100%',
        backgroundColor: 'transparent',
        border: '1px solid #2E2B584D',
        borderRadius: '16px',
        py: 4,
        px: { xs: 2, sm: 4, md: 4, lg: 4 },
        '&:before': {
          display: 'none',
        },
        //force border radius for first and last child
        '&:first-child': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        },
        '&:last-child': {
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon  sx={{ transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)' }} />}
        aria-controls="panel1a-content"
        //disable expand icn rotation
        sx={{
          '& .Mui-expanded': {
            transform: 'rotate(0deg)',
          }
        }}
        id="panel1a-header"
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: 20,
            lineHeight: '24px',
          }}
        >
          {faq.q}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: 16,
            lineHeight: '24px',
            color: '#585765',
          }}
        >
          {faq.a}
        </Typography>
      </AccordionDetails>
    </Accordion >
  )
}

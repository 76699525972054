import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface FullScreenDialogProps {
  open: boolean;
  title: string;
  content: string;
  onClose: () => void;
}

const FullScreenDialog: React.FC<FullScreenDialogProps> = ({ open, title, content, onClose }) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle>
        {title}
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
    </Dialog>
  );
};

export default FullScreenDialog;

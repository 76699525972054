import { Box, Typography, useTheme } from '@mui/material';
import AuthLayout from '../components/AuthLayout';
import imagePassword from '../assets/login-password-image-1.png';
import { useContext, useState } from 'react';
import CustomTextField from '../components/common/CustomTextField';
import CircularButton from '../components/common/CircularButton';
import { loginUser } from '../services/authServices';
import { AuthContext } from '../contexts/AuthContext';
import PhoneInputField from '../components/common/PhoneInput';
import { enqueueSnackbar } from 'notistack';

export default function Login() {

  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState<string>('');
  const { login } = useContext(AuthContext);
  const theme = useTheme();

  const handleLogin = async () => {
    try {
      const phone = phoneNumber.replace(/[^0-9]/g, '');
      const res = await loginUser({ phoneNumber: phone, password });
      localStorage.setItem('token', res.access_token);
      localStorage.setItem('refreshToken', res.refresh_token);
      login(res.access_token, res.refresh_token)
      enqueueSnackbar('Giriş başarılı', { variant: 'success', autoHideDuration: 2000 },)
    } catch (err) {
      enqueueSnackbar('Lütfen doğru bilgiler ile giriş yapınız.', { variant: 'error', autoHideDuration: 2000 })
      console.log(err)
    }
  }

  return (
    <Box>
      <AuthLayout
        image={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                pl: 8
              }}
            >
              <Typography
                sx={{
                  fontSize: 40,
                  fontWeight: 600,
                  color: 'white',
                  lineHeight: 1.3
                }}
              >
                Telefon numaranızı
                <br />
                ve şifrenizi girin
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                pr: 4,
                mt: -3
              }}
            >
              <img
                src={imagePassword}
                alt="login-register"
                style={{
                  maxWidth: 400
                }}
              />
            </Box>
          </Box>
        }
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 493,
            mx: 'auto',
          }}
        >
          <Typography
            sx={{
              color: '#8377FB',
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            Telefon Numaranız
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <PhoneInputField
              phoneNumber={phoneNumber}
              onChange={(phoneNumber) => {
                setPhoneNumber(phoneNumber)
              }}
              sx={{
                mt: 2
              }}
            />
            <CustomTextField
              label="Şifre"
              value={password}
              required
              type='password'
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                mt: 2,
              }}
            />
            <CircularButton
              label='Giriş Yap'
              color={theme.palette.secondary.main}
              textColor={theme.palette.primary.main}
              type='submit'
              sx={{
                mt: 4,
                px: 3,
                py: 1
              }}
            />
          </form>
        </Box>
      </AuthLayout >
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import MyAppbar from './MyAppbar'
import Footer from './Footer'
import { useLocation } from 'react-router-dom'
import SideNav from './SideNav'

type AdminLayoutProps = {
  children: React.ReactNode
}

export default function AdminLayout({ children }: AdminLayoutProps) {

  const loc = useLocation();

  // path does not start with /admin return null
  if (!loc.pathname.startsWith('/admin')) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <MyAppbar />
      <Box
        sx={{
          flex: 1,
          mb: 4,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
          }}
        >
          <SideNav />
        </Box>
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

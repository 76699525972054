import { Box, Grid, Typography } from '@mui/material'
import pic from '../assets/credit-home-variant.png';
import kobi from '../assets/kobi.svg';
import creditCard from '../assets/credit-card.svg';
import home from '../assets/home.svg';
import mevduat from '../assets/mevduat.svg';
import need from '../assets/need.svg';
import vehicle from '../assets/vehicle.svg';
import bank from '../assets/bank.svg';
import CreditVariantItem from './CreditVariantItem';
import { useNavigate } from 'react-router-dom';

export default function CreditHomeVariants() {

  const nav = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          background: '#F8F6F2',
          borderRadius: '32px',
          mx: { xs: 2, sm: 4, md: 8, lg: 12 },
          p: { xs: 2, sm: 4, md: 8, lg: 12 },
          display: 'flex',
          flexDirection: ['column', 'column', 'row'],
          alignItems: 'center',
          gap: 8,
          maxWidth: 1600,
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 428,
          }}
        >
          <img
            src={pic}
            alt="credit-home-variant"
            style={{
              width: '100%',
              minWidth: 300,
              height: 'auto',
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: 40,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            Sana Özel Kredi Deneyimi
          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              mt: 4,
              color: 'text.secondary'
            }}
          >
            Cred, finansal ihtiyaçlarını anlayarak sana en uygun kredi seçeneklerini sunar. İhtiyaçların doğrultusunda en iyi kredi fırsatını Cred ile hemen deneyimle.
          </Typography>
          <Box
            sx={{
              mt: 5
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={6} lg={4}>
                <CreditVariantItem
                  title="Kredi"
                  icon={need}
                  onClick={() => { nav('/kredi-listeleme'); }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={6} lg={4}>
                <CreditVariantItem
                  title="Kredi Kartı"
                  icon={creditCard}
                  onClick={() => { nav('/kredi-karti'); }}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={6} lg={4}>
                <CreditVariantItem
                  title="Banka Müşterisi Ol"
                  icon={bank}
                  onClick={() => { nav('/banka-musterisi-ol'); }}
                />
              </Grid>
              {/* <Grid item xs={6} sm={4} md={6} lg={4}>
                <CreditVariantItem title="Taşıt Kredisi" icon={vehicle} />
              </Grid>
              <Grid item xs={6} sm={4} md={6} lg={4}>
                <CreditVariantItem title="Konut Kredisi" icon={home} />
              </Grid>
              <Grid item xs={6} sm={4} md={6} lg={4}>
                <CreditVariantItem title="Kobi Kredisi" icon={kobi} />
              </Grid>
              <Grid item xs={6} sm={4} md={6} lg={4}>
                <CreditVariantItem title="Mevduat" icon={mevduat} />
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

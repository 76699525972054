import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const registerUser = async (values: any) => {
  const url = `${baseUrl}/auth/register`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        name: values.name,
        surname: values.surname,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: values.password,
        nationalId: values.nationalId,
        birthDate: new Date(values.birthDate),
      }
    )
  });
  const res = await handleResponse(response);

  return res;
}

export const refreshToken = async (token: string) => {
  const url = `${baseUrl}/auth/refreshtoken`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        token
      }
    )
  });

  const res = await handleResponse(response);

  return res;
}

export const loginUser = async (values: any) => {
  const url = `${baseUrl}/auth/login`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        phoneNumber: values.phoneNumber,
        password: values.password,
      }
    )
  });
  const res = await handleResponse(response);
  return res;
}

export const getUser = async (id: number, token: string) => {
  const url = `${baseUrl}/user/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}
import React, { useState } from 'react'
import { Blog } from '../utils/types'
import { Avatar, Box, Paper, Typography } from '@mui/material';

type BlogCardItemProps = {
  blog: Blog;
  onClick: () => void;
}

export default function BlogCardItem({ blog, onClick }: BlogCardItemProps) {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Paper
      elevation={isHovered ? 2 : 0}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        background: '#F9FAFB',
        borderRadius: '12px',
        width: '360px',
        pt: 4,
        pb: 2,
        px: 3,
        height: '100%',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Avatar src={blog.authorImage} alt={blog.author} sx={{ width: 36, height: 36 }} />
            <Box>
              <Typography
                sx={{
                  fontWeight: '600',
                  color: '#111827',
                  lineHeight: '20px',
                  fontSize: '14px'
                }}
              >
                {blog.author}
              </Typography>
              <Typography
                sx={{
                  color: '#111827',
                  lineHeight: '14px',
                  fontSize: '10px'
                }}
              >
                {new Date(blog.createdAt).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 2
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '18px',
                lineHeight: '24px',
                mt: 2,
                color: '#111827',
              }}
            >
              {blog.title}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              mt: 3,
              background: '#FFD2BF',
              borderRadius: '100px',
              py: '5px',
              px: '12px',
              width: 'fit-content'
            }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#831802',
                mb: 0.1
              }}
            >
              Kredi ve Borç yönetimi
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 4,
            }}
          >
            <img
              src={blog.coverImage}
              alt={blog.title}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                aspectRatio: '3/2',
                objectFit: 'cover'
              }}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

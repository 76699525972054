import { Box } from '@mui/material'
import React from 'react'
import BlogDetailContainer from '../containers/BlogDetailContainer'
import { useParams } from 'react-router-dom';

export default function BlogDetail() {

  const { id } = useParams<{ id: string }>();

  return (
    <Box>
      <BlogDetailContainer id={+id!} />
    </Box>
  )
}

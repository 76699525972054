import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const getUsers = async (token: string) => {
  const url = `${baseUrl}/user`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const getUser = async (id: number, token: string) => {
  const url = `${baseUrl}/user/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const updateUser = async (id: number, token: string, values: any) => {
  const url = `${baseUrl}/user/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const deleteUser = async (id: number, token: string) => {
  const url = `${baseUrl}/user/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}
import { Box, Typography, useTheme } from '@mui/material';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';
import { useContext, useEffect, useRef, useState } from 'react';
import BlogCategories from '../components/BlogCategories';
import { AuthContext } from '../contexts/AuthContext';
import { getBlog } from '../services/BlogServices';
import { Blog } from '../utils/types';
import RelationalPosts from '../components/RelationalPosts';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';

type BlogDetailContainerProps = {
  id: number;
};

export default function BlogDetailContainer({ id }: BlogDetailContainerProps) {

  const [blog, setBlog] = useState<Blog>();
  const [loading, setLoading] = useState<boolean>(true);
  const { token } = useContext(AuthContext);
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [titleHeight, setTitleHeight] = useState<number>(0);

  const { data: blogs, error, isLoading } = useSWR<Blog[]>(`${baseUrl}/blog`, (url: string) => fetcher(url, token));


  useEffect(() => {
    if (ref.current) {
      setTitleHeight(ref.current.clientHeight);
    }
  }, [ref]);


  const fetchBlog = async () => {
    try {
      const res = await getBlog(id!, token);
      setBlog(res);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      fetchBlog();
    } else {
      setLoading(false);
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          width: '100%',
          maxWidth: '1600px',
          mx: 'auto',
          mt: 4,
          px: { xs: 2, md: 4 }
        }}
      >
        <Box
          sx={{
            minWidth: '250px !important',
            display: { xs: 'none', md: 'block' },
            mt: `${titleHeight + 56}px`,
          }}
        >
          <BlogCategories />
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: '1000px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Typography
            ref={ref}
            sx={{
              fontSize: 50,
              fontWeight: '500',
              color: 'text.primary',
              textAlign: 'center',
              px: { xs: 2, md: 4 }
            }}
          >
            {blog?.title}
          </Typography>
          <Viewer
            key={`${theme.palette.mode}-${id}`}
            theme={theme.palette.mode}
            initialValue={blog?.content}
          />
          <RelationalPosts
            blogs={blogs}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  )
}

import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const createLoanRule = async (token: string, values: any) => {
  const url = `${baseUrl}/loan-rule`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const getLoanRules = async (token: string) => {
  const url = `${baseUrl}/loan-rule`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const getLoanRule = async (id: number, token: string) => {
  const url = `${baseUrl}/loan-rule/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const updateLoanRule = async (id: number, token: string, values: any) => {
  const url = `${baseUrl}/loan-rule/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const deleteLoanRule = async (id: number, token: string) => {
  const url = `${baseUrl}/loan-rule/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  components:{},
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    }
  },
  typography: {
    fontFamily: 'Gellix, sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#2DC66C'
    },
    secondary: {
      main: '#000000'
    },
    background: {
      default: '#ffffff',
      paper: '#F3F5F7',
    },
    text:{
      primary: '#000000',
    }
  },
});

import { Box, Grid, Typography } from '@mui/material';
import mobilePhone from '../assets/mobile-phone.png';
import React from 'react';
import HomeAdvantage1 from './HomeAdvantage1';
import HomeAdvantage2 from './HomeAdvantage2';
import HomeAdvantage3 from './HomeAdvantage3';

export default function CreditHomeAdvantage() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 2, sm: 4, md: 8, lg: 12 },
          mx: { xs: 2, sm: 4, md: 8, lg: 12 },
          maxWidth: 1600,
          width: '100%',
        }}
      >
        <HomeAdvantage1 />
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <HomeAdvantage2 />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <HomeAdvantage3 />
          </Grid>
        </Grid>
      </Box>
    </Box >
  )
}

import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import FaqItem from './FaqItem';
import CircularButton from './common/CircularButton';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function FaqComponent() {

  const theme = useTheme();
  const nav = useNavigate();

  const lorem = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum. ';

  const faqs = [
    {
      q: 'cred.com.tr" nedir?',
      a: '"cred.com.tr", Türkiye\'deki tüm bankaların kredi tekliflerini tek bir platformda toplayan bir kredi kıyaslama platformudur.Size en uygun krediye hızlıca ulaşmanıza yardımcı olur.',
    },
    {
      q: 'Kullanıcılar için "cred.com.tr" kullanımı ücretsiz midir?',
      a: lorem
    },
    {
      q: 'Kredi sağlık raporu nasıl alınır?',
      a: lorem
    },
    {
      q: 'Hangi bankaların kredi teklifleri "cred.com.tr" üzerinde yer alır?',
      a: lorem
    },
    {
      q: 'Kredi başvurumu "cred.com.tr" üzerinden yapabilir miyim?',
      a: lorem
    }
  ]

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 1600,
        px: { xs: 2, sm: 4, md: 8, lg: 12 },
      }}
    >
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: 60,
          lineHeight: '72px',
        }}
      >
        Cred Hakkında Merak Edilenler
      </Typography>
      <Box
        sx={{
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          gap: 3,
        }}
      >
        {faqs.map((faq, index) => (
          <FaqItem
            key={index}
            faq={faq}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          mt: 10,
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: 20,
          }}
        >
          Daha fazla yardım için
        </Typography>
        <CircularButton
          onClick={() => { nav('/sss') }}
          label="Merak Edilenler"
          color={theme.palette.secondary.main}
          postIcon={<ArrowForward />}
          sx={{
            px: 2,
            fontSize: 16,
          }}
        />
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import React, { useContext } from 'react'
import CreditHomeHeader from '../components/CreditHomeHeader'
import CreditHomeVariants from '../components/CreditHomeVariants'
import CreditHomeAdvantage from '../components/CreditHomeAdvantage'
import CalculationTools from '../components/CalculationTools'
import BannerText from '../components/BannerText'
import CustomerServices from '../components/CustomerServices'
import OurSupports from '../components/OurSupports'
import FaqComponent from '../components/FaqComponent'
import BlogCards from '../components/BlogCards'
import { AuthContext } from '../contexts/AuthContext'
import useSWR from 'swr'
import { Blog } from '../utils/types'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/global'

export default function CreditHome() {


  const { token } = useContext(AuthContext);

  const { data: blogs, error, isLoading } = useSWR<Blog[]>(`${baseUrl}/blog`, (url: string) => fetcher(url, token));


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        width: '100%',
      }}
    >
      <CreditHomeHeader />
      <CreditHomeVariants />
      <BannerText text="Hayallerini Erteleme • Sana özel kredi deneyimi • En iyi kredi fırsatları • Özel ödeme oranları • " />
      <CreditHomeAdvantage />
      <OurSupports />
      <Box
        sx={{
          width: '100%',
          px: { xs: 2, sm: 4, md: 8, lg: 12 },
        }}
      >
        <CalculationTools />
      </Box>

      <BannerText text="Hayallerini Erteleme • Sana özel kredi deneyimi • En iyi kredi fırsatları • Özel ödeme oranları • " />
      <CustomerServices />
      <BlogCards
        blogs={blogs}
        isLoading={isLoading}
      />
      <FaqComponent />
    </Box>
  )
}

import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import CustomSlider from './common/CustomSlider'
import CircularTextField from './common/CircularTextField'
import CircularButton from './common/CircularButton';
import { ArrowForward, ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type IndividualCreditCalculationProps = {
  half?: boolean;
  minAmount?: number;
  maxAmount?: number;
  minTerm?: number;
  maxTerm?: number;
  sliderValue: number;
  setSliderValue: (sliderValue: number) => void;
  creditAmount: string;
  setCreditAmount: (creditAmount: string) => void;
  creditSliderValue: number;
  setCreditSliderValue: (creditSliderValue: number) => void;
  vade: string;
  setVade: (vade: string) => void;
  interestRate: string;
  setInterestRate: (interestRate: string) => void;
  handleCalculate: (values: any) => void;
  interestEmptyError: boolean;
  disableInterestRate?: boolean;
}

export default function IndividualCreditCalculation({
  half,
  minAmount,
  maxAmount,
  minTerm,
  maxTerm,
  sliderValue,
  setSliderValue,
  creditAmount,
  setCreditAmount,
  creditSliderValue,
  setCreditSliderValue,
  vade,
  setVade,
  interestRate,
  setInterestRate,
  handleCalculate,
  interestEmptyError,
  disableInterestRate
}: IndividualCreditCalculationProps
) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeCreditAmount = (creditAmount: string) => {
    setCreditAmount(creditAmount);
    if (creditAmount === '') {
      setCreditSliderValue(10000);
    } else if (parseInt(creditAmount) > 500000) {
      setCreditSliderValue(500000);
    } else {
      setCreditSliderValue(parseInt(creditAmount));
    }
  }

  const handleChangeCreditSliderValue = (creditSliderValue: number) => {
    setCreditSliderValue(creditSliderValue);
    setCreditAmount(creditSliderValue.toString());
  }

  const handleChangeVade = (vade: string) => {
    setVade(vade);
    if (vade === '') {
      setSliderValue(3);
    } else if (parseInt(vade) > 24) {
      setSliderValue(24);
    } else {
      setSliderValue(parseInt(vade));
    }
  }

  const handleChangeSliderValue = (sliderValue: number) => {
    setSliderValue(sliderValue);
    setVade(sliderValue.toString());
  }

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={half ? 12 : disableInterestRate ? 6 : 5} md={half ? 12 : disableInterestRate ? 6 : 5} lg={half ? 12 : disableInterestRate ? 6 : 5} xl={half ? 12 : disableInterestRate ? 6 : 5}>
          {
            half || matches ?
              <Amount
                creditSliderValue={creditSliderValue}
                handleChangeCreditSliderValue={handleChangeCreditSliderValue}
                creditAmount={creditAmount}
                handleChangeCreditAmount={handleChangeCreditAmount}
                minAmount={minAmount}
                maxAmount={maxAmount}
              /> :
              <Vade
                sliderValue={sliderValue}
                handleChangeSliderValue={handleChangeSliderValue}
                vade={vade}
                handleChangeVade={handleChangeVade}
                minTerm={minTerm}
                maxTerm={maxTerm}
              />
          }
        </Grid>
        <Grid item xs={12} sm={half ? 12 : disableInterestRate ? 6 : 5} md={half ? 12 : disableInterestRate ? 6 : 5} lg={half ? 12 : disableInterestRate ? 6 : 5} xl={half ? 12 : disableInterestRate ? 6 : 5}>
          {
            half || matches ?
              <Vade
                sliderValue={sliderValue}
                handleChangeSliderValue={handleChangeSliderValue}
                vade={vade}
                handleChangeVade={handleChangeVade}
                minTerm={minTerm}
                maxTerm={maxTerm}
              /> :
              <Amount
                creditSliderValue={creditSliderValue}
                handleChangeCreditSliderValue={handleChangeCreditSliderValue}
                creditAmount={creditAmount}
                handleChangeCreditAmount={handleChangeCreditAmount}
                minAmount={minAmount}
                maxAmount={maxAmount}
              />
          }
        </Grid>
        {!disableInterestRate && (<Grid item xs={12} sm={half ? 12 : 2} md={half ? 12 : 2} lg={half ? 12 : 2} xl={half ? 12 : 2}>
          <InterestRate
            value={interestRate}
            onChange={(interestRate) => setInterestRate(interestRate)}
            emptyError={interestEmptyError}
          />
        </Grid>)}
      </Grid>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <CircularButton
          label="Hemen Hesapla"

          onClick={() => { handleCalculate({ creditAmount, vade, interestRate }) }}
          color="white"
          textColor={theme.palette.primary.main}
          postIcon={<ArrowForward />}
          sx={{
            px: 3,
            py: 2
          }}
        />
      </Box>
    </Box>
  )
}

type VadeProps = {
  sliderValue: number,
  handleChangeSliderValue: (sliderValue: number) => void,
  vade: string,
  handleChangeVade: (vade: string) => void;
  minTerm: number;
  maxTerm: number;
}

function Vade({ sliderValue, handleChangeSliderValue, vade, handleChangeVade, minTerm, maxTerm }: VadeProps) {

  const theme = useTheme();

  return <Box>
    <Typography
      fontWeight={600}
    >
      Vade
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2.5,
      }}
    >
      <CustomSlider
        value={sliderValue}
        onChange={handleChangeSliderValue}
        max={maxTerm}
        min={minTerm}
        defaultValue={minTerm}
        step={3}
      />
      <CircularTextField
        value={vade}
        onChange={(vade) => {
          if (+vade > maxTerm) {
            handleChangeVade(maxTerm.toString())
          } else {
            handleChangeVade(vade)
          }
        }}
      />
    </Box>
    <Box
      sx={{
        mt: 3,
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'center',
        gap: 1
      }}
    >
      {[3, 6, 9, 12, 24].map((item) => (
        <CircularButton
          label={`${item} Ay`}
          onClick={() => handleChangeSliderValue(item)}
          color={sliderValue === item ? theme.palette.primary.main : 'white'}
          textColor={sliderValue === item ? 'white' : 'black'}
          sx={{
            px: 3,
          }}
        />
      ))}
    </Box>
  </Box>
}

type AmountProps = {
  creditSliderValue: number,
  handleChangeCreditSliderValue: (creditSliderValue: number) => void,
  creditAmount: string,
  handleChangeCreditAmount: (creditAmount: string) => void;
  minAmount: number;
  maxAmount: number;
}

function InterestRate({ value, onChange, emptyError }: { value: string, onChange: (value: string) => void, emptyError: boolean }) {
  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <Typography
      fontWeight={600}
    >
      Faiz Oranı
    </Typography>
    <CircularTextField
      value={value}
      emptyError={emptyError}
      onChange={(value) => {
        const cleanedValue = value.replace(/[^0-9.,]/g, '');
        onChange(cleanedValue.replaceAll(',', '.'));
      }}
      placeholder='Faiz Oranını Giriniz'
    />
  </Box>
}

function Amount({ creditSliderValue, handleChangeCreditSliderValue, creditAmount, handleChangeCreditAmount, minAmount, maxAmount }: AmountProps) {

  return <Box>
    <Typography
      fontWeight={600}
    >
      Kredi Tutarı
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2.5,
      }}
    >
      <CustomSlider
        value={creditSliderValue}
        onChange={handleChangeCreditSliderValue}
        max={maxAmount}
        min={minAmount}
        defaultValue={minAmount}
        step={10000}
      />
      <CircularTextField
        value={creditAmount}
        onChange={(amount) => {
          console.log(amount, maxAmount)
          if (+amount > maxAmount) {
            handleChangeCreditAmount(maxAmount.toString())
          } else {
            handleChangeCreditAmount(amount)
          }
        }}
      />
    </Box>
  </Box>
}

import Box from '@mui/material/Box';
import { ReactComponent as MevduatIcon } from '../assets/1.svg';
import { ReactComponent as BireyselKrediIcon } from '../assets/2.svg';
import { ReactComponent as TasitKredisiIcon } from '../assets/3.svg';
import { ReactComponent as KonutKredisiIcon } from '../assets/4.svg';

import * as React from 'react';
import CalculationToolTabItem from './CalculationToolTabItem';
import { Tab, Tabs } from '@mui/material';

type CalculationToolsTabsProps = {
  value: string;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export default function CalculationToolsTabs({ value, handleChange }: CalculationToolsTabsProps) {

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Box>
        <Tabs
          value={value}
          variant="fullWidth"
          scrollButtons="auto"
          sx={{
            '& .MuiTab-root': {
              fontSize: 20,
              fontWeight: 500,
              textTransform: 'none',
              color: '#000000',
              borderBottom: '2px solid #BDCEDF',
              minHeight: 0,
              pr: 3
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& .Mui-selected': {
              borderTop: '1px solid #000',
              borderLeft: '1px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid transparent',
              borderRadius: '16px 16px 0 0',
              mb: '2px',
              color: '#000000 !important',
            },
            '& .MuiTab-iconWrapper': {
              mr: 2,
              ml: 1,
              width: 24
            },
          }}
          onChange={handleChange}
        >
          <Tab
            icon={<BireyselKrediIcon />}
            iconPosition='start'
            label={'İhtiyaç Kredisi'}
            value={'2'}
          />
          <Tab
            icon={<TasitKredisiIcon />}
            iconPosition='start'
            label={'Taşıt Kredisi'}
            value={'3'}
          />
          <Tab
            icon={<KonutKredisiIcon />}
            iconPosition='start'
            label={'Konut Kredisi'}
            value={'4'}
          />
        </Tabs>
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import React, { useContext } from 'react'
import BlogLayoutSelect from '../components/BlogLayoutSelect'
import { AuthContext } from '../contexts/AuthContext';
import useSWR from 'swr';
import { Blog } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import CircularButton from '../components/common/CircularButton';
import { LoadingButton } from '@mui/lab';

export default function AdminBlogHomePage() {

  const { token, user } = useContext(AuthContext);
  const { data: blogs, error } = useSWR<Blog[]>(`${baseUrl}/blog`, (url: string) => fetcher(url, token));



  if (!blogs) return (
    <Box>
      Loading...
    </Box>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          py: 3,
          maxWidth: 600,
          width: '100%',
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <BlogLayoutSelect
          blogs={blogs}
          index={1}
        />
        <BlogLayoutSelect
          blogs={blogs}
          index={2}
        />
        <BlogLayoutSelect
          blogs={blogs}
          index={3}
        />
        <BlogLayoutSelect
          blogs={blogs}
          index={4}
        />
        <BlogLayoutSelect
          blogs={blogs}
          index={5}
        />
        <BlogLayoutSelect
          blogs={blogs}
          index={6}
        />
        <BlogLayoutSelect
          blogs={blogs}
          index={7}
        />
        <BlogLayoutSelect
          blogs={blogs}
          index={8}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            variant='contained'
          >
            Kaydet
          </LoadingButton>
        </Box>
      </Box>
    </Box >
  )
}

import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CreditHome from "./views/CreditHome";
import { lightTheme } from "./theme";
import CreditApply from "./views/CreditApply";
import CreditList from "./views/CreditList";
import CreditCalculation from "./views/CreditCalculation";
import CreditCardList from "./views/CreditCardList";
import Login from "./views/Login";
import Register from "./views/Register";
import LoginRegisterMain from "./views/LoginRegisterMain";
import Guard from "./components/Guard";
import AuthProvider from "./contexts/AuthContext";
import { SnackbarProvider } from 'notistack';
import AdminLayout from "./components/AdminLayout";
import AdminHome from "./views/AdminHome";
import AdminUsers from "./views/AdminUsers";
import AdminLoanRoles from "./views/AdminLoanRoles";
import AdminTanents from "./views/AdminTanents";
import AdminLogs from "./views/AdminLogs";
import FaqPage from "./views/FaqPage";
import AboutUs from "./views/AboutUs";
import Blog from "./views/Blog";
import AdminBlogList from "./views/AdminBlogList";
import AddEditBlog from "./views/AddEditBlog";
import BlogDetail from "./views/BlogDetail";
import BecomeBankCustomer from "./views/BecomeBankCustomer";
import AdminCategories from "./views/AdminCategories";
import Disclosure from "./views/Disclosure";
import UserAgreement from "./views/UserAgreement";
import ScrollToTop from "./components/ScrollToTop";
import Contact from "./views/Contact";
import AdminBlogHomePage from "./views/AdminBlogHomePage";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Router>
            <ScrollToTop>
              <Routes>
                <Route path="/login" element={<Login />} />
                {/* <Route path="/register" element={<Register />} /> */}
                {/* <Route path="/login-register-main" element={<LoginRegisterMain />} /> */}
              </Routes>
              <Layout>
                <Routes>
                  <Route path="/" element={<CreditHome />} />
                  <Route path="/sss" element={<FaqPage />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/iletisim" element={<Contact />} />
                  <Route path="/aydinlatma-metni" element={<Disclosure />} />
                  <Route path="/kullanici-sozlesmesi" element={<UserAgreement />} />
                  <Route path="/banka-musterisi-ol" element={<BecomeBankCustomer />} />
                  <Route path="/blog/:id" element={<BlogDetail />} />
                  <Route path="/hakkimizda" element={<AboutUs />} />
                  <Route path="/kredi-basvuru" element={<CreditApply />} />
                  <Route path="/kredi-listeleme" element={<CreditList />} />
                  <Route path="/kredi-hesaplama" element={<CreditCalculation />} />
                  <Route path="/kredi-karti" element={<CreditCardList />} />
                </Routes>
              </Layout>
              <Guard>
                <AdminLayout>
                  <Routes>
                    <Route path="/admin" element={<AdminHome />} />
                    <Route path="/admin/users" element={<AdminUsers />} />
                    <Route path="/admin/blogs" element={<AdminBlogList />} />
                    <Route path="/admin/categories" element={<AdminCategories />} />
                    <Route path="/admin/blog/new" element={<AddEditBlog />} />
                    <Route path="/admin/blog/:id/edit" element={<AddEditBlog />} />
                    <Route path="/admin/loan-rules" element={<AdminLoanRoles />} />
                    <Route path="/admin/tanents" element={<AdminTanents />} />
                    <Route path="/admin/logs" element={<AdminLogs />} />
                    <Route path="/admin/blog-home-page" element={<AdminBlogHomePage />} />
                  </Routes>
                </AdminLayout>
              </Guard>
            </ScrollToTop>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default App;

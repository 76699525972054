import { Box, Button, Typography } from '@mui/material'
import healthReport from '../assets/financial-health-report-pic.png'
import React from 'react'

export default function HomeAdvantage3() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        background: '#BDF6F4',
        borderRadius: '32px',
        height: '100% !important',
        position: 'relative',
        p: { xs: 4, sm: 4, md: 8, lg: 8 }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 24,
          right: 24,
          background: 'white',
          borderRadius: '32px',
          padding: '6px 33px 6px 33px',
        }}
      >
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 600,
            lineHeight: '24.26px',
          }}
        >
          YAKINDA
        </Typography>
      </Box>
      <img
        src={healthReport}
        alt="filter-credit"
        style={{
          maxWidth: '350px',
          aspectRatio: '1/1',
          width: '100%',
          margin: '0px auto',
        }}
      />
      <Typography
        fontWeight={600}
        fontSize={34}
      >
        Finansal Sağlık Raporunu Hemen Al
      </Typography>
      <Typography
        fontSize={22}
      >
        Cred’in sana özel hazırladığı finansal sağlık raporu ile kredi güven puanını, borçluluk durumunu, kullandığın kredi miktarını, harcamalarının gelirine oranını, aylık ne kadar kredi ödeyebileceğini, geri ödeme alışkanlıklarını ve daha birçok finansal bilgiyi anında görüntüle.
      </Typography>
      {/* <Box
        sx={{
          width: '100%',
        }}
      >
        <Button
          variant='text'
          sx={{
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '24.26px',
            textTransform: 'none',
            textDecoration: 'underline',
            color: 'black',
            pl: 0
          }}
        >
          Rapor Al
        </Button>
      </Box> */}
    </Box>
  )
}

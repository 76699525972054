import { Box, Grid, Typography } from '@mui/material'

import akbank from '../assets/akbank.svg';
import enpara from '../assets/enpara.svg';
import fibabanka from '../assets/fibabanka.svg';
import halkbank from '../assets/halkbank.svg';
import qnb from '../assets/qnbbank.svg';
import vakifbank from '../assets/vakifbank.svg';
import yapikredi from '../assets/logo-yapikredi.png';
import aktifbank from '../assets/aktifbank.svg';
import ingbank from '../assets/ingbank.svg';
import burganbank from '../assets/burganbank.svg';
import denizbank from '../assets/denizbank.svg';
import isbank from '../assets/isbank.svg';
import tebbank from '../assets/tebbank.svg';
import ziraatbank from '../assets/ziraatbank.svg';
import odeabank from '../assets/odeabank.png';

import React from 'react'

export default function OurSupports() {

  const logos = [
    yapikredi,
    halkbank,
    qnb,
    vakifbank,
    akbank,
    enpara,
    fibabanka,
    aktifbank,
    ingbank,
    burganbank,
    denizbank,
    isbank,
    tebbank,
    ziraatbank,
    odeabank,
  ]

  return (
    <Box
      sx={{
        my: 4,
      }}
    >
      <Box
        sx={{
          maxWidth: 1500,
          mx: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 40, sm: 40, md: 48, lg: 56, xl: 64 },
            fontWeight: 500,
            lineHeight: '72px',
            textAlign: 'center',
            mb: 6
          }}
        >
          İş Ortaklarımız
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            gap: 3,
            mt: 2,
            px: 2
          }}
        >
          {logos.map(logo => (
            <img
              src={logo}
              alt="logo"
              style={{
                width: 175,
                height: 100,
                padding: 2,
                objectFit: 'contain',
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

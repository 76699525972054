import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { ArrowForward, Expand } from '@mui/icons-material';
import { List, ListItem, ListItemAvatar, ListItemButton, Popover, SwipeableDrawer, Tab, Tabs, useTheme } from '@mui/material';
import CircularButton from './common/CircularButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { useEffect } from 'react';
import SideNav from './SideNav';
import { Role } from '../utils/types';
import Logo from './Logo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

const pages = ['Hakkımızda', 'Kredi', 'Kredi Kartı', 'Blog'];
const futures = ['Kredi', 'Kredi Kartı', 'Banka Müşterisi Ol', 'Hesaplama Araçları'];

export default function MyAppbar() {
  const theme = useTheme();

  const nav = useNavigate();
  const loc = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const { token, logout } = React.useContext(AuthContext);
  const [value, setValue] = React.useState('credit');
  const [background, setBackground] = React.useState('white');
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { user } = React.useContext(AuthContext);

  const ref = React.useRef<HTMLDivElement>(null);

  const [anchorElFuture, setAnchorElFuture] = React.useState<null | HTMLElement>(null);
  const futureOpen = Boolean(anchorElFuture);

  const handleSelectFuture = (page: string) => {
    switch (page) {
      case 'Kredi':
        nav('/kredi-listeleme');
        break;
      case 'Kredi Kartı':
        nav('/kredi-karti');
        break;
      case 'Banka Müşterisi Ol':
        nav('/banka-musterisi-ol');
        break;
      case 'Hesaplama Araçları':
        nav('/kredi-hesaplama');
        break;
      case 'Sigorta':
        break;
      case 'Mevduat':
        break;
      default:
        break;
    }
    setAnchorElFuture(null);
  }

  console.log(ref.current?.getBoundingClientRect().right);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (loc.pathname.startsWith('/admin')) {
      setDrawerOpen(true);
      return;
    }
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    if (loc.pathname === '/') {
      setValue('');
      setBackground('#8377FB');
    } else if (loc.pathname === '/kredi-basvuru') {
      setBackground('#8377FB');
    } else {
      setBackground('white');
    }

    if (loc.pathname.startsWith('/admin')) {
      setValue('admin-panel');
    }

  }, [loc.pathname])

  let timeoutId = null;

  const handleMouseEnter = (e) => {
    if (timeoutId) clearTimeout(timeoutId);
    setAnchorElFuture(e.currentTarget);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      if (futureOpen) setAnchorElFuture(null);
    }, 100); // delay of 500ms
  };

  const handleClick = (page: string) => {
    switch (page) {
      case 'Kredi':
        nav('/kredi-listeleme');
        break;
      case 'Kredi Kartı':
        nav('/kredi-karti');
        break;
      case 'Blog':
        nav('/blog');
        break;
      case 'Hakkımızda':
        nav('/hakkimizda');
        break;
      case 'Sigorta':
        break;
      case 'Mevduat':
        break;
      case 'admin-panel':
        nav('/admin');
        break;
      default:
        break;
    }
    setAnchorElNav(null);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        background: background,
        borderBottom: '1px solid ' + (background === 'white' ? '#F5F5F5' : '#F5F5F540'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 110,
        zIndex: 100,
      }}
      position='relative'
    >
      {user?.role === Role.SUPERADMIN && (
        <SwipeableDrawer
          anchor='left'
          open={drawerOpen}
          onClose={() => { setDrawerOpen(false) }}
          onOpen={() => { setDrawerOpen(true) }}
        >
          <SideNav
            handleCloseDrawer={() => { setDrawerOpen(false) }}
          />
        </SwipeableDrawer>
      )}
      <Box
        sx={{
          maxWidth: 1600,
          width: '100%',
          height: '100%',
          px: 2
        }}
      >
        <Toolbar disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {/* desktop logo */}
          <Logo
            onClick={() => nav('/')}
            sx={{
              display: { xs: 'none', md: 'flex' },
              px: { sm: 3, md: 7, lg: 11 },
            }}
          />

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: background === 'white' ? 'black' : 'white' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleClick(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* mobile logo */}
          <Logo
            onClick={() => nav('/')}
            sx={{
              display: { xs: 'flex', md: 'none' },
              px: { sm: 3, md: 7, lg: 11 },
            }}
          />
          {/* desktop menu */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                height: '100%',
                '& .MuiTabs-indicator': {
                  backgroundColor: background === 'white' ? 'black' : 'white',
                  height: '1px',
                  color: background === 'white' ? 'black' : 'white' + ' !important',
                },
                '& .MuiTab-root': {
                  color: background === 'white' ? 'black' : 'white' + ' !important',
                  textTransform: 'none',
                  fontSize: 20,
                  height: '115px',
                  fontWeight: 500,
                  '&:hover': {
                    color: background === 'white' ? 'black' : 'white',
                    opacity: 0.8,
                  },
                },
                '& .Mui-selected': {
                  color: background === 'white' ? 'black' : 'white' + ' !important',
                },
              }}
            >
              <Tab
                label={
                  <Typography
                    sx={{
                      color: background === 'white' ? 'black' : 'white',
                      fontSize: 20,
                      fontWeight: 500,
                    }}
                  >
                    Hakkımızda
                  </Typography>
                }
                value="about-us" onClick={() => nav('/hakkimizda')} />
              <Tab
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                label={
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography
                        ref={ref}
                        sx={{
                          color: background === 'white' ? 'black' : 'white',
                          fontSize: 20,
                          fontWeight: 500,
                        }}
                      >
                        Özellikler
                      </Typography>
                      <ExpandMoreIcon
                        sx={{
                          color: background === 'white' ? 'black' : 'white',
                          fontSize: 20,
                          fontWeight: 500,
                          mt: 0.5
                        }}
                      />
                    </Box>
                  </Box>
                }
                value="future" onClick={(e) => { futureOpen ? setAnchorElFuture(null) : setAnchorElFuture(e.currentTarget) }} />
              <Tab
                label={
                  <Typography
                    sx={{
                      color: background === 'white' ? 'black' : 'white',
                      fontSize: 20,
                      fontWeight: 500,
                    }}
                  >
                    Blog
                  </Typography>
                }
                value="blog" onClick={() => nav('/blog')} />
            </Tabs>
          </Box>
        </Toolbar>
      </Box>
      {futureOpen ? (
        <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            width: '250px',
            height: '200px',
            position: 'absolute',
            bottom: -201,
            left: ref.current?.getBoundingClientRect().right - 125,
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: '0px 0px 16px 16px',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          <List
            sx={{
              width: '100%',
            }}
          >
            {futures.map(page => {
              return (
                <ListItem
                  disableGutters
                  sx={{
                    p: 0,
                    height: 50,
                  }}
                >
                  <ListItemButton
                    disableGutters
                    onClick={() => { handleSelectFuture(page) }}
                    sx={{
                      width: '100%',
                      px: 2,
                      height: '100%',
                    }}
                  >

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <CircleIcon
                        sx={{
                          color: '#8277FA',
                          height: 5,
                        }}
                      />
                      <Typography
                        sx={{
                          color: '#8277FA',
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {page}
                      </Typography>
                    </Box>
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Box>
      ) : null}
    </AppBar>
  );
}
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type ScrollToTopProps = {
  children: any
}

export default function ScrollToTop({ children }: ScrollToTopProps) {
  const { key, pathname } = useLocation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [key, matches, pathname]);

  return <>{children}</>;
}

import IframeResizer from 'iframe-resizer-react'
import React from 'react'

type PDFViewerProps = {
  file: string
}

export default function PDFViewer({ file }: PDFViewerProps): JSX.Element {
  return (
    <IframeResizer
      src={file}
      style={{ width: '1px', minWidth: '100%', height: '100vh' }}
      allowFullScreen
      title="PDF Viewer"
    />
  )
}

import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const createCategory = async (token: string, values: any) => {
  const url = `${baseUrl}/category`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const getCategories = async (token: string) => {
  const url = `${baseUrl}/category`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const getCategory = async (id: number, token: string) => {
  const url = `${baseUrl}/category/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}

export const updateCategory = async (id: number, token: string, values: any) => {
  const url = `${baseUrl}/category/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const deleteCategory = async (id: number, token: string) => {
  const url = `${baseUrl}/category/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  const res = await handleResponse(response);
  return res;
}
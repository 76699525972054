import { Box, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

type FooterSectionItemProps = {
  title: string;
  items: any[];
}

export default function FooterSectionItem({ title, items }: FooterSectionItemProps) {

  const nav = useNavigate();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 24,
          fontWeight: 600,
          lineHeight: '46px',
        }}
      >
        {title}
      </Typography>
      <Box>
        {items.map((item, index) => (
          <Typography
            onClick={() => nav(item.link)}
            key={index}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: '30px',
              color: '#000000',
              '&:hover': {
                color: '#8377FB',
                cursor: 'pointer',
              }
            }}
          >
            {item.title}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CalculationToolsTabs from './CalculationToolsTabs'
import IndividualCreditCalculation from './IndividualCreditCalculation';
import useSWR from 'swr';
import { LoanRule, LoanType } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { AuthContext } from '../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

type CalculationToolsProps = {
  disabletitle?: boolean;
  disableInterestRate?: boolean;
}


export default function CalculationTools({ disabletitle, disableInterestRate }: CalculationToolsProps) {

  const [value, setValue] = React.useState('2');
  const { token } = React.useContext(AuthContext);
  const [minAmaount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(0);
  const [minTerm, setMinTerm] = React.useState(0);
  const [maxTerm, setMaxTerm] = React.useState(0);
  const [sliderValue, setSliderValue] = React.useState(12);
  const [creditAmount, setCreditAmount] = React.useState('50000');
  const [vade, setVade] = React.useState('12');
  const [creditSliderValue, setCreditSliderValue] = React.useState(50000);
  const [interestRate, setInterestRate] = React.useState('0');
  const [interestEmptyError, setInterestEmptyError] = React.useState(false);

  const nav = useNavigate();
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { data: rules, error } = useSWR<LoanRule[]>(`${baseUrl}/loan-rule`, (url: string) => fetcher(url, token));

  useEffect(() => {
    if (rules) {
      if (value === '2') { // individual credit
        const individualCreditRules = rules.filter(rule => rule.loanType === LoanType.PERSONAL_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...individualCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...individualCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      } else if (value === '3') { // vehicle credit
        const vehicleCreditRules = rules.filter(rule => rule.loanType === LoanType.VEHICLE_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...vehicleCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...vehicleCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      } else if (value === '4') { // housing credit
        const housingCreditRules = rules.filter(rule => rule.loanType === LoanType.MORTGAGE_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...housingCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...housingCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      }
    }
  }, [value, rules])

  useEffect(() => {
    if (value === '2') {
      const individualCreditRules = rules?.filter(rule => rule.loanType === LoanType.PERSONAL_LOAN);
      const ruleData = individualCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    } else if (value === '3') {
      const vehicleCreditRules = rules?.filter(rule => rule.loanType === LoanType.VEHICLE_LOAN);
      const ruleData = vehicleCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    } else if (value === '4') {
      const housingCreditRules = rules?.filter(rule => rule.loanType === LoanType.MORTGAGE_LOAN);
      const ruleData = housingCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    }
  }, [creditAmount, value])

  const handleCalculate = (values: any) => {
    if (value === '3' || value === '4') {
      if (interestRate === '' || interestRate === '0') {
        setInterestEmptyError(true);
        return;
      }
    }
    nav(`/kredi-hesaplama?loanType=${value}&vade=${vade}&amount=${creditAmount}&interestRate=${values.interestRate}`);
  }

  useEffect(() => {

    if (searchParams.get('vade')) {
      setVade(searchParams.get('vade') || '');
      setSliderValue(parseInt(searchParams.get('vade') || ''));
      console.log(searchParams.get('vade'));
    }

    if (searchParams.get('amount')) {
      setCreditAmount(searchParams.get('amount') || '');
      setCreditSliderValue(parseInt(searchParams.get('amount') || ''));
    }

    if (searchParams.get('interestRate')) {
      setInterestRate(searchParams.get('interestRate') || '');
    }

    if (searchParams.get('loanType')) {
      setValue(searchParams.get('loanType') || '2');
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('interestRate') && searchParams.get('interestRate') !== '0') {
      setInterestEmptyError(false);
    }
    if (value === '2') {
      setInterestEmptyError(false);
    }
  }, [searchParams.get('interestRate'), value])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          background: '#F3F5F7',
          borderRadius: '32px',
          // mx: { xs: 2, sm: 4, md: 8, lg: 12 },
          pt: 4,
          px: { xs: 2, sm: 2, md: 4, lg: 4 },
          maxWidth: 1600,
          width: '100%',
        }}
      >
        {!disabletitle && (
          <>
            <Typography
              sx={{
                fontSize: { xs: 20, sm: 24, md: 32, lg: 40 },
              }}
              fontWeight={600}
            >
              Hesaplama Araçları
            </Typography>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 400,
                lineHeight: '20px',
                mt: 2
              }}
            >
              Hesaplama Araçlarıyla ile Hızlıca finansal sonuçlara ulaşın!
            </Typography>
          </>
        )}
        <Box mt={4}>
          <CalculationToolsTabs
            value={value}
            handleChange={handleChange}
          />
          <Box
            p={3}
          >
            <IndividualCreditCalculation
              minAmount={minAmaount}
              maxAmount={maxAmount}
              minTerm={minTerm}
              maxTerm={maxTerm}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              creditAmount={creditAmount}
              setCreditAmount={setCreditAmount}
              vade={vade}
              setVade={setVade}
              creditSliderValue={creditSliderValue}
              setCreditSliderValue={setCreditSliderValue}
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              handleCalculate={handleCalculate}
              interestEmptyError={interestEmptyError}
              disableInterestRate={disableInterestRate}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

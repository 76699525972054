import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Blog } from '../utils/types';
import BlogCardItem from './BlogCardItem';
import Scroller from './Scroller';

type BlogCardsProps = {
  blogs: Blog[];
  isLoading?: boolean;
}

export default function BlogCards({ blogs, isLoading }: BlogCardsProps) {

  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [canScrollToRight, setCanScrollToRight] = useState(true);
  const [canScrollToLeft, setCanScrollToLeft] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref, blogs])

  const forward = () => {
    if (ref.current) {
      ref.current.scrollBy({ left: 360, behavior: 'smooth' });
    }
  }

  const back = () => {
    if (ref.current) {
      ref.current.scrollBy({ left: -360, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (ref.current && blogs && blogs.length > 0) {
      ref.current.addEventListener('scroll', () => {
        if (ref.current) {
          setCanScrollToRight(ref.current.scrollLeft < ref.current.scrollWidth - ref.current.clientWidth);
          setCanScrollToLeft(ref.current.scrollLeft > 0);
        }
      })
    }
  }, [ref, blogs])

  if (isLoading || !blogs || blogs.length === 0) {
    return <div></div>
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 1600,
        pt: 12,
        px: { xs: 2, sm: 4, md: 8, lg: 12 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 48, sm: 48, md: 60, lg: 60 },
            fontWeight: 500,
          }}
        >
          Cred Blog
        </Typography>
        {isOverflow && (
          <Box>
            <Scroller
              forward={forward}
              back={back}
              canScrollToLeft={canScrollToLeft}
              canScrollToRight={canScrollToRight}
            />
          </Box>
        )}
      </Box>
      <Box
        ref={ref}
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          width: '100%',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          gap: 2,
        }}
      >
        {
          blogs.map((blog, index) => (
            <Box>
              <BlogCardItem
                blog={blog}
                key={index}
                onClick={() => { }}
              />
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

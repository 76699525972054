import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, useTheme, Tooltip } from '@mui/material';
import { PaymentPlanItem } from '../hooks/useCreditCalculator';
import InfoIcon from '@mui/icons-material/Info';

type PaymentPlanTableProps = {
  paymentPlan: PaymentPlanItem[]
}

const PaymentPlanTable = ({ paymentPlan }: PaymentPlanTableProps) => {

  // Toplam değerlerin hesaplanması
  const totalPayment = paymentPlan.reduce((acc, item) => acc + item.installment, 0);
  const totalPrincipal = paymentPlan.reduce((acc, item) => acc + item.principal, 0);
  const totalInterest = paymentPlan.reduce((acc, item) => acc + item.interest, 0);
  const totalKKDF = paymentPlan.reduce((acc, item) => acc + item.kkdf, 0);
  const totalBSMV = paymentPlan.reduce((acc, item) => acc + item.bsmv, 0);

  // Yuvarlama işlemleri
  const formatValue = (value: number) => value.toFixed(2);

  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'], // Responsive layout
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: [2, 4], // Responsive gap
          width: '100%',
          background: theme.palette.background.paper,
          borderRadius: '32px',
          mt: 2,
          py: [2, 4], // Responsive padding
          pt: 4,
          px: { xs: 2, sm: 2, md: 4, lg: 4 },
          maxWidth: 1600,
        }}
      >
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', mb: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="Ödeme Planı Tablosu">
            <TableHead>
              <TableRow>
                <TableCell>Ay</TableCell>
                <TableCell align="right">Taksit (₺)</TableCell>
                <TableCell align="right">Ana Para (₺)</TableCell>
                <TableCell align="right">Faiz (₺)</TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    KKDF (₺)
                    <Tooltip title="Kaynak Kullanımı Destekleme Fonu" placement="top">
                      <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    BSMV (₺)
                    <Tooltip title="Banka Sigorta Muameleleri Vergisi" placement="top">
                      <InfoIcon fontSize="small" sx={{ ml: 1, color: 'text.secondary' }} />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell align="right">Bakiye (₺)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentPlan.map((row, index) => (
                <TableRow key={index} sx={{ bgcolor: index % 2 ? 'action.hover' : 'background.paper' }}>
                  <TableCell>{row.month}</TableCell>
                  <TableCell align="right">{row.installment}₺</TableCell>
                  <TableCell align="right">{row.principal}₺</TableCell>
                  <TableCell align="right">{row.interest}₺</TableCell>
                  <TableCell align="right">{row.kkdf}₺</TableCell>
                  <TableCell align="right">{row.bsmv}₺</TableCell>
                  <TableCell align="right">{row.balance}₺</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Toplam</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{formatValue(totalPayment)}₺</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{Math.round(+formatValue(totalPrincipal))}₺</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{formatValue(totalInterest)}₺</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{formatValue(totalKKDF)}₺</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{formatValue(totalBSMV)}₺</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PaymentPlanTable;

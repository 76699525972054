// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
  border-bottom: none !important;
  /* Alt çizgiyi kaldır */
}`, "",{"version":3,"sources":["webpack://./src/components/TextEditor.css"],"names":[],"mappings":"AAAA;;;;;;EAME,8BAA8B;EAC9B,uBAAuB;AACzB","sourcesContent":[".toastui-editor-contents h1,\n.toastui-editor-contents h2,\n.toastui-editor-contents h3,\n.toastui-editor-contents h4,\n.toastui-editor-contents h5,\n.toastui-editor-contents h6 {\n  border-bottom: none !important;\n  /* Alt çizgiyi kaldır */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

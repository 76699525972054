import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'

type ScrollerProps = {
  forward: () => void;
  back: () => void;
  canScrollToRight: boolean;
  canScrollToLeft: boolean;
}

export default function Scroller({ forward, back, canScrollToRight, canScrollToLeft }: ScrollerProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        gap: 2,
      }}
    >
      {/* <Box>
        <Typography>
          1/3
        </Typography>
      </Box> */}
      <Box>
        <IconButton
          onClick={() => back()}
          disabled={!canScrollToLeft}
        >
          <ArrowBackIos />
        </IconButton>
        <IconButton
          disabled={!canScrollToRight}
          onClick={() => forward()}
        >
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </Box>
  )
}

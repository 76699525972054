import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { useEffect, useRef } from 'react';
import './TextEditor.css';

type TextEditorProps = {
  content: string;
  setContent: (content: string) => void;
}

export default function TextEditor({ content, setContent }: TextEditorProps) {

  const editorEl = useRef<Editor | null>(null);

  const valueChanged = () => {
    const contentData: string = editorEl.current?.getInstance().getMarkdown() || "";
    setContent(contentData);
  };

  useEffect(() => {
    editorEl.current?.getInstance().addHook("change", valueChanged)
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="text-editor"
    >
      <Editor
        ref={editorEl}
        initialValue={content}
        previewStyle="vertical"
        height="calc(100vh - 200px)"
        initialEditType="wysiwyg"
        useCommandShortcut={true}
        language='tr-TR'
        toolbarItems={[
          ["heading", "bold", "italic", "strike"], // underlineToolbarItem  => Customize toolbar item
          ["hr", "quote"],
          ["ul", "ol", "task", "indent", "outdent"],
          ["table", "image", "link"],
          ["code", "codeblock"],
        ]}
      />
    </div>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import AdminCategoriesContainer from '../containers/AdminCategoriesContainer'

export default function AdminCategories() {
  return (
    <Box>
      <AdminCategoriesContainer />
    </Box>
  )
}

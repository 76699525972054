import { Box, Typography } from '@mui/material';
import { type } from 'os'
import React, { useContext, useEffect, useState } from 'react'
import { Role, Blog } from '../utils/types';
import { AuthContext } from '../contexts/AuthContext';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { createBlog, deleteBlog, getBlog, updateBlog } from '../services/BlogServices';
import BlogForm from '../components/BlogForm';
import LoadingComponent from '../components/LoadingComponent';
import DeleteDialog from '../components/DeleteDialog';

type AddEditBlogContainerProps = {
  id: number;
}

export default function AddEditBlogContainer({ id }: AddEditBlogContainerProps) {
  const [blog, setBlog] = useState<Blog>();
  const [loading, setLoading] = useState<boolean>(true);
  const [blogSending, setBlogSending] = useState<boolean>(false);
  const { token } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const nav = useNavigate();

  const fetchBlog = async () => {
    try {
      const res = await getBlog(id!, token);
      setBlog(res);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      fetchBlog();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleSubmit = async (values: any) => {
    try {
      const finestValues = {
        ...values,
        category: {
          connect: {
            id: values.category.id
          }
        }
      }
      setBlogSending(true);
      if (id) {
        await updateBlog(id, token, finestValues);
      } else {
        await createBlog(token, finestValues);
      }
      enqueueSnackbar('Blog başarıyla güncellendi.', { variant: 'success' });
      nav('/admin/blogs');
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Blog oluşturulurken bir hata oluştu.', { variant: 'error' });
    } finally {
      setBlogSending(false);
    }
  }

  const handleDelete = async () => {
    try {
      await deleteBlog(id!, token);
      nav('/admin/blogs');
      enqueueSnackbar('Blog silindi.', { variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Blog silinirken bir hata oluştu.', { variant: 'error' });
    } finally {
      setDeleteDialogOpen(false);
      setDeleting(false);
    }
  }

  return (
    <Box>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 24,
              textAlign: 'center',
            }}
          >
            {id ? 'Blog Düzenle' : 'Blog Ekle'}
          </Typography>
          <BlogForm
            initialValues={blog}
            onSubmit={handleSubmit}
            onDelete={id ? () => setDeleteDialogOpen(true) : undefined}
            blogSending={blogSending}
          />
        </Box>
      )}
      <DeleteDialog
        title='Blogu sil'
        description='Bu blogu silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => { handleDelete() }}
        deleting={deleting}
      />
    </Box>
  )
}

import { Box, Typography } from '@mui/material'
import pic from '../assets/about-us-row-3-pic.png'
import React from 'react'

export default function Row3() {
  return (
    <Box
      sx={{
        width: '100%',
        background: '#8377FB',
        color: 'white',
        py: 18
      }}
    >
      <Box
        sx={{
          maxWidth: 1024,
          width: '100%',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: ['column', 'column', 'row'],
          gap: 4
        }}
      >
        <Box
          sx={{
            gap: 4,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 50,
              lineHeight: '40px'
            }}
          >
            Neden
            <br />
            Cred?
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 18,
              lineHeight: '22px',
              mt: 2
            }}
          >
            <span style={{ fontWeight: 600 }}>
              Tüm Bankalar Bir Arada
            </span>
            <br />
            Cred, Türkiye'deki tüm bankaların kredi tekliflerini tek bir çatı altında toplar, böylece size en uygun krediye hızlı ve kolayca ulaşmanızı sağlar.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 18,
              lineHeight: '22px',
              mt: 2
            }}
          >
            <span style={{ fontWeight: 600 }}>
              Kişiselleştirilmiş Çözümler:
            </span>
            <br />
            Finansal sağlık raporlarınızı inceleyerek size özel kredi teklifleri sunarız. Bu sayede ihtiyaçlarınıza uygun finansal çözümleri kolayca bulmanıza yardımcı oluruz.
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <img
            src={pic}
            alt="pic"
            style={{
              margin: 'auto',
              width: '100%',
              maxWidth: 500,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

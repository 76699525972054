import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import CreditCardHeader from '../components/CreditCardHeader'
import PopulerCreditCard from '../components/PopulerCreditCard'
import BestCreditCard from '../components/BestCreditCard'
import { baseUrl } from '../utils/constants'
import DynamicIframe from '../components/DynamicIframe'
import LoadingComponent from '../components/LoadingComponent'

export default function CreditCardListContainer() {


  const [loading, setLoading] = React.useState(true);
  const [iframeUrl, setIframeUrl] = React.useState('');

  const fetchIframe = async () => {
    try {
      const res = await fetch(`${baseUrl}/offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          loanType: 4,
        })
      })
      const data = await res.json();
      setIframeUrl(data.iframe);

    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    fetchIframe();
  }, []);

  const onLoad = () => {
    setLoading(false);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        width: '100%',
      }}
    >
      <Box
        sx={{
          my: 10
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 40, md: 48, lg: 52, xl: 60 },
            fontWeight: '600',
            textAlign: 'center',
          }}>
          Paranı Kontrol Et, Hayatını Yönet:
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 32, md: 36, lg: 40, xl: 48 },
            fontWeight: '400',
            textAlign: 'center',
          }}
        >
          Senin İçin Seçtiğimiz En İyi Kartlar!
        </Typography>
      </Box>
      {/* <CreditCardHeader /> */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >

        {loading && (<Typography><LoadingComponent /></Typography>)}
        {iframeUrl && (
          <DynamicIframe src={iframeUrl} onLoad={onLoad} />
        )}
      </Box>
      {/* <PopulerCreditCard />
      <BestCreditCard /> 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          width: '100%',
        }}
      >
        <Button
          variant='outlined'
          sx={{
            borderRadius: '12px',
            maxWidth: '400px',
            fontSize: 20,
            width: '100%',
            textTransform: 'none',
          }}
        >
          Daha Fazla Göster
        </Button>
      </Box>*/}
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import AddEditBlogContainer from '../containers/AddEditBlogContainer'
import { useParams } from 'react-router-dom';

export default function AddEditBlog() {

  const { id } = useParams<{ id: string }>();

  return (
    <Box
      sx={{
        width: '100%',
        padding: 2,
      }}
    >
      <AddEditBlogContainer id={id ? +id : undefined} />
    </Box>
  )
}

import { ArrowForward } from '@mui/icons-material'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import greenHeaderRight from '../assets/green-header-right.svg'
import brandText from '../assets/home-brand-text.svg'
import pic from '../assets/credit-home-header-pic-3.png'
import CircularButton from './common/CircularButton'
export default function CreditHomeHeader() {

  const nav = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: '100%',
        background: '#8377FB',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <img
        src={greenHeaderRight}
        alt="green-header-right"
        style={{
          position: 'absolute',
          display: matches ? 'block' : 'none',
          right: 0,
          top: 0,
          maxHeight: '100%',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-between',
          maxWidth: 1600,
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: ['center', 'flex-start'],
            justifyContent: 'center',
            flexDirection: 'column',
            position: { sm: 'relative', md: 'absolute' },
            width: { sm: '100%', md: '60%', lg: '60%' },
            py: { xs: 2, sm: 4, md: 8, lg: 10 },
            px: { xs: 2, sm: 4, md: 8, lg: 12 },
          }}
        >
          <img
            src={brandText}
            alt="brand-text"
            style={{
              width: '100%',
              height: 'auto',
              marginBottom: 20,
              maxWidth: 750
            }}
          />
          <Typography
            sx={{
              color: 'white',
              fontSize: { sm: 24, md: 26, lg: 28 },
            }}
          >
            Keşfet, karşılaştır, karar ver. Senin için en iyi olanı
            bulmak ve finansal seçimlerini desteklemek için buradayız.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              width: '100%',
              mt: 8
            }}
          >
            <CircularButton
              onClick={() => { nav('/kredi-basvuru') }}
              label="Hemen Başvur!"
              color="white"
              postIcon={<ArrowForward />}
              sx={{
                px: 4,
                py: 1.5,
                fontSize: 20
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            mt: 8,
            mr: 20
          }}
        >
          <img
            src={pic}
            alt="credit-home-header-pic"
            style={{
              maxWidth: 1111,
              maxHeight: 600,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const NumberFormatter = (number: number | string): string => {
  // Sayıyı string formatına çeviriyoruz
  let numberString = number.toString();

  // Ondalık kısmı ve tam sayı kısmını ayırıyoruz
  let [integerPart, decimalPart] = numberString.split('.');

  // Tam sayı kısmını ters çevirip, her üç karakterden sonra bir nokta ekliyoruz
  integerPart = integerPart
    .split('')
    .reverse()
    .join('')
    .replace(/(\d{3})(?=\d)/g, '$1.')
    .split('')
    .reverse()
    .join('');

  // Eğer ondalık kısım varsa, onu da ekliyoruz
  return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
};

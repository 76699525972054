import { Box, Typography } from '@mui/material';
import FaqItem from '../components/FaqItem';

export default function FaqPage() {

  const lorem = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum. ';

  const faqs = [
    {
      q: 'cred.com.tr" nedir?',
      a: '"cred.com.tr", Türkiye\'deki tüm bankaların kredi tekliflerini tek bir platformda toplayan bir kredi kıyaslama platformudur.Size en uygun krediye hızlıca ulaşmanıza yardımcı olur.',
    },
    {
      q: 'Kullanıcılar için "cred.com.tr" kullanımı ücretsiz midir?',
      a: lorem
    },
    {
      q: 'Kredi sağlık raporu nasıl alınır?',
      a: lorem
    },
    {
      q: 'Hangi bankaların kredi teklifleri "cred.com.tr" üzerinde yer alır?',
      a: lorem
    },
    {
      q: 'Kredi başvurumu "cred.com.tr" üzerinden yapabilir miyim?',
      a: lorem
    },
    {
      q: 'Kredi puanımı nasıl iyileştirebilirim?',
      a: lorem
    },
    {
      q: 'Kredi başvurumu kabul edilmezse ne yapmalıyım?',
      a: lorem
    },
    {
      q: '"cred.com.tr" ile kişisel bilgilerimi paylaştığımda güvenli miyim?',
      a: lorem
    },
    {
      q: 'Kredi sağlık raporum ne kadar sürede hazır olur?',
      a: lorem
    },
    {
      q: '"cred.com.tr" ile iletişim kurmak için nasıl destek alabilirim?',
      a: lorem
    }
  ]

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pt: 15
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 2, sm: 4, md: 8, lg: 12 },
          mx: { xs: 2, sm: 4, md: 8, lg: 12 },
          maxWidth: 1600,
          width: '100%',
          mb: 15
        }}
      >
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: { xs: 40, sm: 50, md: 60 },
            lineHeight: { xs: '48px', sm: '60px', md: '72px' },
          }}
        >
          Cred Hakkında Merak Edilenler
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            gap: 3,
          }}
        >
          {faqs.map((faq, index) => (
            <FaqItem
              key={index}
              faq={faq}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

import { Box, Typography } from '@mui/material'
import headerPic from '../assets/about-us-header-pic.png'
import React from 'react'
import line from '../assets/black-line.svg'

export default function AboutUsHeader() {
  return (
    <Box
      sx={{
        background: `url(${headerPic})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        pt: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          py: 18
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: 40, sm: 50, md: 70, lg: 80 },
            lineHeight: '70px',
            textAlign: 'center',
            mb: 2,
            maxWidth: 1000,
          }}
        >
          Finansal Özgürlüğünüz İçin
          Buradayız
        </Typography>
        <img
          src={line}
          alt="line"
          style={{
            display: 'block',
            margin: 'auto',
            marginBottom: 2,
            stroke: 'black',
          }}
        />
      </Box>

    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import CreditCalculationContainer from '../containers/CreditCalculationContainer'

export default function CreditCalculation() {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <CreditCalculationContainer />
    </Box>
  )
}

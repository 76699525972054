import React from 'react'
import logo from '../assets/logo.svg'
import { Box, SxProps } from '@mui/material'

type LogoProps = {
  sx?: SxProps,
  onClick?: () => void,
}

export default function Logo({ sx, onClick }: LogoProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        ...sx,
      }}
    >
      <img src={logo} alt="logo" />
    </Box>
  )
}

import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardActions, CardMedia, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Blog, Category } from '../utils/types';
import CategorySelect from './CategorySelect';
import TextEditor from './TextEditor';

type BlogFormProps = {
  initialValues?: Blog;
  onSubmit: (values: any) => void;
  blogSending: boolean;
  onDelete?: () => void;
}

export default function BlogForm({ initialValues, onSubmit, blogSending, onDelete }: BlogFormProps) {

  const [title, setTitle] = useState<string>(initialValues?.title || '');
  const [content, setContent] = useState<string>(initialValues?.content || '');
  const [coverImage, setCoverImage] = useState<string>(initialValues?.coverImage || '');
  const [author, setAuthor] = useState<string>(initialValues?.author || '');
  const [authorImage, setAuthorImage] = useState<string>(initialValues?.authorImage || '');
  const [category, setCategory] = useState<Category>(initialValues?.category);

  const handleImageSelect = (e: any, type: string) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (type === 'authorImage') {
        setAuthorImage(e.target?.result as string);
      } else if (type === 'coverImage') {
        setCoverImage(e.target?.result as string);
      }
    }
    reader.readAsDataURL(file);
  }

  const handleImageDelete = (type: string) => {
    if (type === 'authorImage') {
      setAuthorImage('');
    } else if (type === 'coverImage') {
      setCoverImage('');
    }
  }

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({ title, content, coverImage, author, authorImage, category });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                fullWidth
                label="Başlık"
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                fullWidth
                label="Yazar Adı"
                value={author}
                required
                onChange={(e) => setAuthor(e.target.value)}
              />
              <CategorySelect
                value={category}
                onChange={(category) => setCategory(category)}
              />
              <Card sx={{ display: 'inline-block' }}>
                <Typography variant="h6" component="div" sx={{ p: 2 }}>
                  Yazar Resmi
                </Typography>
                <CardMedia
                  component="img"
                  image={authorImage || 'https://via.placeholder.com/400x400'}
                  alt="author-image"
                  sx={{ maxHeight: 350, objectFit: 'contain' }}
                />
                <CardActions>
                  <Button component="label" size="large" color="primary">
                    RESİM EKLE
                    <input type="file" hidden accept="image/*" onChange={(e) => handleImageSelect(e, 'authorImage')} />
                  </Button>
                  <IconButton aria-label="delete" size="small" color="primary" onClick={() => handleImageDelete('authorImage')}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
              <Card sx={{ display: 'inline-block' }}>
                <Typography variant="h6" component="div" sx={{ p: 2 }}>
                  Kapak Resmi
                </Typography>
                <CardMedia
                  component="img"
                  image={coverImage || 'https://via.placeholder.com/400x400'}
                  alt="cover-image"
                  sx={{ maxHeight: 350, objectFit: 'contain' }}
                />
                <CardActions>
                  <Button component="label" size="large" color="primary">
                    RESİM EKLE
                    <input type="file" hidden accept="image/*" onChange={(e) => handleImageSelect(e, 'coverImage')} />
                  </Button>
                  <IconButton aria-label="delete" size="small" color="primary" onClick={() => handleImageDelete('coverImage')}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextEditor
              content={content}
              setContent={setContent}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mt: 2
          }}
        >
          {onDelete && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={onDelete}
            >
              Sil
            </Button>
          )}
          <LoadingButton
            loading={blogSending}
            variant="contained"
            type="submit"
          >
            Kaydet
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}

import { Box, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import BlogCardItem from './BlogCardItem';
import Scroller from './Scroller';

type RelationalPostsProps = {
  blogs?: any[];
  isLoading?: boolean;
};

export default function RelationalPosts({ blogs, isLoading }: RelationalPostsProps) {

  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [canScrollToRight, setCanScrollToRight] = useState(true);
  const [canScrollToLeft, setCanScrollToLeft] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref, blogs])

  const forward = () => {
    if (ref.current) {
      ref.current.scrollBy({ left: 360, behavior: 'smooth' });
    }
  }

  const back = () => {
    if (ref.current) {
      ref.current.scrollBy({ left: -360, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (ref.current && blogs && blogs.length > 0) {
      ref.current.addEventListener('scroll', () => {
        if (ref.current) {
          setCanScrollToRight(ref.current.scrollLeft < ref.current.scrollWidth - ref.current.clientWidth);
          setCanScrollToLeft(ref.current.scrollLeft > 0);
        }
      })
    }
  }, [ref, blogs])

  if (isLoading || !blogs || blogs.length === 0) {
    return <div></div>
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 1600,
        pt: 12,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: 28,
            fontWeight: 700,
          }}
        >
          İlgili Yazılar
        </Typography>
        {isOverflow && (
          <Box>
            <Scroller
              forward={forward}
              back={back}
              canScrollToLeft={canScrollToLeft}
              canScrollToRight={canScrollToRight}
            />
          </Box>
        )}
      </Box>
      <Box
        ref={ref}
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          width: '100%',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          gap: 2,
        }}
      >
        {
          blogs.map((blog, index) => (
            <Box>
              <BlogCardItem
                blog={blog}
                key={index}
                onClick={() => { }}
              />
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

import { Box, Rating, Typography } from '@mui/material'
import React from 'react'
import CommentCardItem from './CommentCardItem'

export default function CustomerServices() {

  const comments = [
    {
      title: 'Yeni Ev Sahibi',
      name: 'Selin Y.',
      comment: 'Cred sayesinde evim için en uygun kredi seçeneğini buldum. Kredi tekliflerini karşılaştırmak ve detaylı analizleri görmek gerçekten harikaydı.'
    },
    {
      title: 'İşletme Sahibi',
      name: 'Emre Y.',
      comment: 'Cred ekibiyle çalışmak gerçekten harikaydı! Araba alım sürecimde bana en uygun kredi seçeneklerini sunarak, finansal yükümü hafifletmeme yardımcı oldular. İhtiyaçlarımı tam anladılar ve bana mükemmel bir çözüm sundular..'
    },
    {
      title: 'Yeni Ev Sahibi',
      name: 'Andaç Y.',
      comment: 'Cred, kişisel projelerimi hayata geçirmeme yardımcı olmak için harika bir araç. Özellikle kredi tekliflerini karşılaştırarak hangi seçeneğin bana en uygun olduğunu görmek çok değerliydi.'
    }
  ]

  return (
    <Box
      sx={{
        width: '100%',
        background: 'black',
        pb: 12
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row', 'row'],
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          py: { xs: 4, sm: 4, md: 8, lg: 8 },
          px: { xs: 4, sm: 4, md: 8, lg: 8 },
        }}
      >
        <Typography
          sx={{
            color: 'white'
          }}
        >
          CRED MÜŞTERİ DENEYİMİ
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 3
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: '35px'
            }}
          >
            400.000'in üstünde kullanıcı
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 3
            }}
          >
            <Typography
              sx={{
                color: 'white',
              }}
              fontSize={70}
            >
              4.8
            </Typography>
            <Box>
              <Rating
                sx={{
                  color: '#4FA9A2',
                  fontSize: '40px'
                }}
                name="read-only"
                value={4.8}
                readOnly
              />
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '14px'
                }}
              >
                Score from sigortayeri.com
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          gap: [2, 2, 4, 4],
          px: [2, 2, 4, 4]
        }}
      >
        {comments.map((item, index) => (
          <CommentCardItem
            title={item.title}
            name={item.name}
            comment={item.comment}
            key={index}
          />
        ))}
      </Box>
    </Box>
  )
}

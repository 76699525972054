import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import mail from '../assets/mail.svg'
import map from '../assets/map.svg'
import PersonBanner from '../components/PersonBanner'
import person from '../assets/contact-person.svg';

export default function Contact() {

  const infos = [
    {
      title: "MERSİS Numarası:",
      description: "0730081906800001"
    },
    {
      title: "Ticaret Unvanı:",
      description: "Pionr Teknoloji ve Danışmanlık Teknoloji A.Ş."
    },
    {
      title: "Adres:",
      description: "HQFSM Mah. Poligon Cad. Buyaka 2 Sitesi Kule: 3 No: 8C/1 Umraniye / Istanbul"
    },
    {
      title: "Innovation Center",
      description: "Dijitalpark Teknokent Cekmekoy Yerleskesi, Kirazlidere Mah. Eski Ankara Cad. Cekmekoy / Istanbul"
    },
  ]

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1600px',
        margin: '0 auto',
        px: { xs: 2, sm: 4, md: 6, lg: 8 },
        py: { xs: 4, sm: 6, md: 8, lg: 10 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 28, sm: 32, md: 48, lg: 60 },
          fontWeight: 600,
          textAlign: 'center',
          pb: { xs: 4, sm: 6, md: 8, lg: 10 }
        }}
      >
        Size nasıl yardımcı olabiliriz?
      </Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                gap: 4,
              }}
            >
              <Box
                sx={{
                  background: '#F9FAFB',
                  borderRadius: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4,
                  gap: 2,
                  height: '100%',
                }}
              >
                <img
                  src={mail}
                  alt="mail"
                  style={{
                    width: '24px',
                    height: 'auto'
                  }}
                />
                <Typography
                  sx={{
                    color: '#8377FB',
                    fontSize: '22px',
                    fontWeight: 500,
                    lineHeight: '27px',
                  }}
                >
                  destek@cred.com.tr
                </Typography>
                <Typography
                  sx={{
                    color: '#4B5563',
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Soru ve yorumlarınız için biz e-posta yoluyla her zaman ulaşabilirsiniz.
                </Typography>
              </Box>
              <Box
                sx={{
                  background: '#FFFFFF',
                  borderRadius: '20px',
                  border: '1px solid #E5E7EB',
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4,
                  gap: 2,
                  height: '100%',
                  boxShadow: '0px 8px 20px 0px #0F1D340F'
                }}
              >
                <img
                  src={map}
                  alt="map"
                  style={{
                    width: '24px',
                    height: 'auto'
                  }}
                />
                <Typography
                  sx={{
                    color: '#8377FB',
                    fontSize: '22px',
                    fontWeight: 500,
                    lineHeight: '27px',
                  }}
                >
                  Şirket Bilgileri
                </Typography>
                {
                  infos.map((info, index) => (
                    <Box>
                      <Typography
                        sx={{
                          color: '#4B5563',
                          fontSize: 12,
                          fontWeight: 500,
                          lineHeight: '24px',
                        }}
                      >
                        {info.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#4B5563',
                          fontSize: 16,
                          fontWeight: 500,
                          lineHeight: '24px',
                        }}
                      >
                        {info.description}
                      </Typography>
                    </Box>
                  ))
                }
                <Typography
                  sx={{
                    color: '#4B5563',
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <PersonBanner person={person} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

import { Box, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import akbank from '../assets/akbank.png';
import garanti from '../assets/garanti.png';
import yapikredi from '../assets/yapikredi.png';
import ziraat from '../assets/ziraat.png';
import IndividualCreditCalculation from '../components/IndividualCreditCalculation';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { LoanRule, LoanType } from '../utils/types';
import DynamicIframe from '../components/DynamicIframe';
import { NumberFormatter } from '../utils/utils';
import LoadingComponent from '../components/LoadingComponent';

export default function CreditListContainer() {

  const [minAmaount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(0);
  const [minTerm, setMinTerm] = React.useState(0);
  const [maxTerm, setMaxTerm] = React.useState(0);
  const [sliderValue, setSliderValue] = React.useState(12);
  const [creditAmount, setCreditAmount] = React.useState('50000');
  const [interestRate, setInterestRate] = React.useState('0');
  const [vade, setVade] = React.useState('12');
  const [loading, setLoading] = React.useState(true);
  const [iframeUrl, setIframeUrl] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [creditSliderValue, setCreditSliderValue] = React.useState(50000);
  const [pageInitialLoad, setPageInitialLoad] = React.useState(false);
  const loc = useLocation();
  const searchParams = new URLSearchParams(loc.search);
  const { token } = useContext(AuthContext);
  const nav = useNavigate();
  const ref = useRef<any>();

  const { data: rules, error } = useSWR<LoanRule[]>(`${baseUrl}/loan-rule`, (url: string) => fetcher(url, token));

  useEffect(() => {
    if (rules) {
      const value = searchParams.get('loanType') || '2';
      if (value === '2') { // individual credit
        const individualCreditRules = rules.filter(rule => rule.loanType === LoanType.PERSONAL_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...individualCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...individualCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      } else if (value === '3') { // vehicle credit
        const vehicleCreditRules = rules.filter(rule => rule.loanType === LoanType.VEHICLE_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...vehicleCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...vehicleCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      } else if (value === '4') { // housing credit
        const housingCreditRules = rules.filter(rule => rule.loanType === LoanType.MORTGAGE_LOAN);
        // every rule has a minAmount and maxAmount find the min and max of them
        const minAmountData = Math.min(...housingCreditRules.map(rule => rule.minAmount));
        const maxAmountData = Math.max(...housingCreditRules.map(rule => rule.maxAmount));
        setMinAmount(minAmountData);
        setMaxAmount(maxAmountData);
        setCreditAmount(minAmountData.toString());
        setCreditSliderValue(minAmountData);
      }
    }
  }, [rules, searchParams.get('loanType')]);

  useEffect(() => {
    const value = searchParams.get('loanType') || '2';
    if (value === '2') {
      const individualCreditRules = rules?.filter(rule => rule.loanType === LoanType.PERSONAL_LOAN);
      const ruleData = individualCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    } else if (value === '3') {
      const vehicleCreditRules = rules?.filter(rule => rule.loanType === LoanType.VEHICLE_LOAN);
      const ruleData = vehicleCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    } else if (value === '4') {
      const housingCreditRules = rules?.filter(rule => rule.loanType === LoanType.MORTGAGE_LOAN);
      const ruleData = housingCreditRules?.find(rule => rule.minAmount <= creditSliderValue && rule.maxAmount >= creditSliderValue);
      if (ruleData) {
        setMinTerm(ruleData.minTerm);
        setMaxTerm(ruleData.maxTerm);
        if (!(sliderValue >= ruleData.minTerm && sliderValue <= ruleData.maxTerm)) {
          setSliderValue(ruleData.minTerm);
          setVade(ruleData.minTerm.toString());
        }
      }
    }
  }, [creditAmount])

  const fetchIframe = async () => {
    try {
      setLoading(true);
      setIframeUrl('');
      const res = await fetch(`${baseUrl}/offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          loanType: 1,
          loanAmount: parseInt(searchParams.get('amount') || ''),
          maturity: parseInt(searchParams.get('vade') || '')
        })
      })
      const data = await res.json();
      if (data.iframe) {
        setIframeUrl(data.iframe);
        setErrorText('');
        setHasError(false);
      } else {
        setHasError(true);
        setErrorText('Bu kriterlere uygun kredi bulunamadı');
      }

    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {

    if (searchParams.get('vade')) {
      setVade(searchParams.get('vade') || '');
      setSliderValue(parseInt(searchParams.get('vade') || ''));
    }

    if (searchParams.get('amount')) {
      setCreditAmount(searchParams.get('amount') || '');
      setCreditSliderValue(parseInt(searchParams.get('amount') || ''));
      fetchIframe();
    }

    if (!(searchParams.get('vade') && searchParams.get('amount'))) {
      setIframeUrl('');
      setLoading(false);
    }

  }, []);

  useEffect(() => {
    if (!pageInitialLoad) {
      setPageInitialLoad(true);
      return;
    }
    if (searchParams.get('vade') && searchParams.get('amount')) {
      fetchIframe();
    } else {
      setIframeUrl('');
      setLoading(false);
    }
  }, [searchParams.get('vade'), searchParams.get('amount')]);

  const theme = useTheme();

  const onLoad = () => {
    setLoading(false);
  }

  const handleCalculate = (values: any) => {
    if (!Boolean(+values.interestRate)) {
      nav(`/kredi-listeleme?loanType=2&vade=${vade}&amount=${creditAmount}`);
    } else {
      nav(`/kredi-hesaplama?loanType=2&vade=${vade}&amount=${creditAmount}&interestRate=${values.interestRate}`);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        width: '100%',
        height: '100%',
        px: { xs: 2, sm: 4, md: 6, lg: 8 },
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 28, sm: 32, md: 48, lg: 60 },
            fontWeight: 600,
            textAlign: 'center',
            py: { xs: 4, sm: 6, md: 8, lg: 10 }
          }}
        >
          {searchParams.get('vade') ? `${searchParams.get('vade')} ay vadeli ${NumberFormatter(searchParams.get('amount'))}₺ ihtiyaç kredileri` : 'İhtiyaç Kredisi: Kredi Hesapla, İdeal Teklifi Bul ve Hemen Başvur'}
        </Typography>
      </Box>
      <Box
        sx={{
          background: theme.palette.background.paper,
          borderRadius: 4,
          width: '100%',
          p: { xs: 2, sm: 4, md: 6, lg: 8 },
        }}
      >
        <IndividualCreditCalculation
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          creditAmount={creditAmount}
          setCreditAmount={setCreditAmount}
          vade={vade}
          setVade={setVade}
          creditSliderValue={creditSliderValue}
          setCreditSliderValue={setCreditSliderValue}
          interestRate={interestRate}
          setInterestRate={setInterestRate}
          handleCalculate={handleCalculate}
          minAmount={minAmaount}
          maxAmount={maxAmount}
          minTerm={minTerm}
          maxTerm={maxTerm}
          interestEmptyError={false}
          disableInterestRate={true}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >

          {loading && (<Typography><LoadingComponent /></Typography>)}
          {hasError && (<Typography>{errorText}</Typography>)}
          {!loading && iframeUrl && (
            <DynamicIframe src={iframeUrl} onLoad={onLoad} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
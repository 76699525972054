import React, { useEffect, useState } from 'react'
import SimpleDialog from './SimpleDialog';

export default function DynamicIframe({ src, onLoad }: any) {
  const [iframeHeight, setIframeHeight] = useState('auto');
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');

  useEffect(() => {
    const handleResize = (event: MessageEvent) => {
      console.log(event.data)
      if (typeof event.data === 'object' && event.data.pageHeight) {
        const pageHeight = event.data.pageHeight;
        setIframeHeight(`${pageHeight}px`);
      }

      if (typeof event.data === 'object' && event.data.isKVKK) {
        console.log('isKVKK')
        setTitle(event.data.KVKKTitle);
        setContent(event.data.KVKK);
        setOpen(true);
      }

      if (typeof event.data === 'object' && event.data.isUserAggrement) {

        setTitle(event.data.userAgreementTitle);
        setContent(event.data.userAgreement);
        setOpen(true);
        console.log(event.data.userAgreement)
      }

      if (typeof event.data === 'object' && event.data.redirectUrl) {
        //open in new tab
        window.open(event.data.redirectUrl, '_blank');
      }

    };

    window.addEventListener('message', handleResize);

    // Temizlik işlemi
    return () => {
      window.removeEventListener('message', handleResize);
    };
  }, []);

  console.log(open)

  return (
    <>
      <iframe
        id="myIframe"
        src={src}
        style={{
          margin: '24px auto',
        }}
        width="100%"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        height={iframeHeight}
        onLoad={onLoad}
        frameBorder="0"
      />
      <SimpleDialog
        open={open}
        title={title}
        content={content}
        onClose={() => setOpen(false)}
      />
    </>

  );
};


import { Box, Typography } from '@mui/material'
import React from 'react'

type BannerTextProps = {
  text: string
}

export default function BannerText({ text }: BannerTextProps) {
  return (
    <Box
    sx={{
      width: 'calc(100vw - 48px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',    }}
    >
      <Typography
        sx={{
          fontSize: { xs: 20, sm: 24, md: 32, lg: 40 },
          color: '#64C5BD',
          whiteSpace: 'nowrap',
          //infinite loop
          
          animation: 'marquee 10s linear infinite',
          '@keyframes marquee': {
            '0%': { transform: 'translate(0, 0)' },
            '100%': { transform: 'translate(-100%, 0)' },
          },
        }}
      >
        {Array.from(Array(50).keys()).map((_, index) => (
          <>
            {text}
          </>
        ))}

      </Typography>
    </Box>
  )
}

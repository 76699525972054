import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

export default function LoadingComponent() {
  return (
    <Box
    sx={{
      p:5
    }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  )
}

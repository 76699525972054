import { Box, Avatar, Typography, Button } from '@mui/material';
import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type BlogPostItemProps = {
  author: string;
  coverImage?: string;
  authorImage: string;
  date: string;
  title: string;
  onClick: () => void;
  isWide?: boolean;
};

export default function BlogPostItem({
  author,
  coverImage,
  authorImage,
  date,
  title,
  onClick,
  isWide = false,
}: BlogPostItemProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'relative',
        borderRadius: '16px',
        overflow: 'hidden',
        cursor: 'pointer',
        aspectRatio: isWide ? '3 / 1' : '4 / 3',
      }}
    >
      {coverImage && (
        <img
          src={coverImage}
          alt="Cover"
          style={{
            width: '100%',
            height: '100%', // Eklenen satır: Yükseklik 100% olarak ayarlandı
            objectFit: 'cover',
            borderRadius: 16,
          }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            borderRadius: '12px',
            height: '100%',
            backgroundColor: coverImage ? 'rgba(0, 0, 0, 0.3)' : '#F9FAFB',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 32,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 4
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Avatar src={authorImage} alt={author} sx={{ width: 36, height: 36 }} />
            <Box>
              <Typography
                sx={{
                  fontWeight: '600',
                  color: coverImage ? '#fff' : '#111827',
                  lineHeight: '20px',
                  fontSize: '14px'
                }}
              >
                {author}
              </Typography>
              <Typography
                sx={{
                  color: coverImage ? '#fff' : '#111827',
                  lineHeight: '14px',
                  fontSize: '10px'
                }}
              >
                {date}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                color: coverImage ? '#fff' : '#111827',
                fontSize: { xs: '18px', md: '22px' },
                fontWeight: 700,
                lineHeight: '28px',
                maxWidth: { xs: '90%', md: '50%' }
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Button
              sx={{
                textTransform: 'none',
                color: coverImage ? '#fff' : '#6D28D9',
                pl: 0,
              }}
              variant='text'
              endIcon={<KeyboardArrowRightIcon />}
            >
              Yazıyı Oku
            </Button>
          </Box>

        </Box>
        {/* <Box sx={{ position: 'absolute', top: '50%', left: '20%', transform: 'translate(-50%, -50%)', color: '#fff', zIndex: 1, display: 'flex', gap: 2 }}>
         
        </Box>

        <Box sx={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', width: '80%', zIndex: 1 }}>
          <Typography variant="h6" sx={{ color: '#fff', mb: 1 }}>{title}</Typography>
          <Button variant="contained" onClick={onClick}>Yazıyı Oku</Button>
        </Box> */}
      </Box>
    </Box>
  );
}

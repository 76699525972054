import { Box, SxProps, Typography } from '@mui/material';
import React from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import trFlag from '../../assets/tr-flag.png';

type PhoneInputProps = {
  phoneNumber: string;
  onChange: (phoneNumber: string) => void;
  sx?: SxProps;
}

export default function PhoneInputField({ phoneNumber, onChange, sx }: PhoneInputProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        ...sx
      }}
    >
      <Box>
        <Typography
          sx={{
            color: '#6B7280',
            fontSize: 14,
            fontWeight: 500,
            mb: 1
          }}
        >
          Ülke Kodu
        </Typography>
        <Box
          sx={{
            height: '56px',
            border: 'none',
            borderRadius: '8px',
            backgroundColor: '#F3F4F6',
            width: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1
          }}
        >
          <img
            src={trFlag}
            alt="tr-flag"
            style={{
              width: 24,
              height: 24,
            }}
          />
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            +90
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%'
        }}
      >
        <Typography
          sx={{
            color: '#6B7280',
            fontSize: 14,
            fontWeight: 500,
            mb: 1
          }}
        >
          Telefon Numarası
        </Typography>
        <PhoneInput
          country="tr-TR"
          value={phoneNumber}
          onChange={(phone) => onChange(phone)}
          placeholder='534 123 45 67'
          disableCountryCode
          defaultMask="... ... .. .."
          alwaysDefaultMask
          buttonStyle={{
            display: 'none'
          }}
          inputProps={{
            required: true
          }}
          inputStyle={{
            width: '100%',
            height: '56px',
            border: 'none',
            borderRadius: '8px',
            backgroundColor: '#F3F4F6',
            paddingLeft: '16px',
          }}
        />
      </Box>
    </Box>
  )
}

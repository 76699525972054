import { Box, SxProps, TextField } from '@mui/material';
import React from 'react'

type CircularTextFieldProps = {
  label?: string;
  value: string;
  onChange: (text: string) => void;
  sx?: SxProps;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  autoComplete?: string;
  placeholder?: string;
  emptyError?: boolean;
}

export default function CircularTextField({ placeholder, label, value, onChange, sx, type = 'text', required = false, multiline = false, rows = 1, emptyError }: CircularTextFieldProps) {


  const borderColor = emptyError ? 'red' : '#BDCEDF';

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <TextField
        required={required}
        multiline={multiline}
        rows={rows}
        label={label}
        placeholder={placeholder}
        helperText={emptyError ? 'Bu alan boş bırakılamaz' : ''}
        autoComplete="new-password"
        variant="outlined"
        value={value}
        size="small"
        type={type}
        onChange={(e) => { onChange(e.target.value) }}
        inputProps={{
          min: 0,
          style: {
            textAlign: 'right', fontSize: 18
          }
        }}
        sx={{
          my: 1,
          marginRight: 'auto',
          textAlign: 'right',
          borderRadius: 32,
          width: '100%',
          '& .MuiInputBase-input::placeholder': {
            fontSize: '12px',
            textAlign: 'left',
            transform: 'translateY(-20%)',

          },
          '& .MuiOutlinedInput-root': {
            borderRadius: 32,
            background: 'white',
            '& fieldset': {
              borderColor: borderColor,
            },
            '&:hover fieldset': {
              borderColor: borderColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: borderColor,
            },
          },
          ...sx,
        }}
      />
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './Footer'
import MyAppbar from './MyAppbar'

type LayoutProps = {
  children: React.ReactNode
}

const nonLayoutPaths = ['/login', '/register', '/login-register-main']

export default function Layout({ children }: LayoutProps) {

  const loc = useLocation();

  // path starts with /admin return null
  if (loc.pathname.startsWith('/admin')) return null;

  if (nonLayoutPaths.includes(loc.pathname)) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 2px)',
      }}
    >
      <MyAppbar />
      <Box
        sx={{
          flex: 1,
          mb: 4,
          zIndex: 1,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

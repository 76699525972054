import { Box, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardInfo } from '../components/BankCreditCardItem';
import CircularButton from '../components/common/CircularButton';
import { useEffect } from 'react';
import React from 'react';
import { NumberFormatter } from '../utils/utils';

type CustomCardInfoProps = {
  totalPayment: number,
  interestRate: number,
  monthlyPayment: number,
  handleClick: () => void
}

export default function CustomCardInfo({ totalPayment, interestRate, monthlyPayment, handleClick }: CustomCardInfoProps) {

  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'], // Responsive layout
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: [2, 4], // Responsive gap
          width: '100%',
          background: theme.palette.background.paper,
          borderRadius: '32px',
          mt: 2,
          py: [2, 4], // Responsive padding
          pt: 4,
          px: { xs: 2, sm: 2, md: 4, lg: 4 },
          maxWidth: 1600,
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 700,
            display: 'flex',
            flexDirection: ['column', 'row'], // Responsive layout
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            px: [2, 2], // Responsive padding
          }}
        >
          <CardInfo title="Toplam Ödeme" value={NumberFormatter(totalPayment) + '₺'} />
          <CardInfo title="Faiz Oranı" value={interestRate + '%'} />
          <CardInfo title="Aylık Ödeme" value={NumberFormatter(monthlyPayment) + '₺'} />
        </Box>

        <Box
          sx={{
            width: [2, 2], // Responsive width
            height: 100,
            display: ['none', 'block', 'block', 'block'], // Responsive display
            background: '#00000033'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CircularButton
            label="Ödeme Planı"
            color={theme.palette.secondary.main}
            onClick={() => { handleClick() }}
            sx={{
              width: [150, 200], // Responsive width
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

import { Button, SxProps } from '@mui/material';
import React from 'react'

type CircularButtonProps = {
  onClick?: () => void;
  label: string;
  postIcon?: React.ReactNode;
  preIcon?: React.ReactNode;
  color?: string;
  textColor?: string;
  sx?: SxProps;
  fontSize?: number;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export default function CircularButton({ type, disabled, onClick, label, postIcon, preIcon, color, sx, textColor, fontSize = 16 }: CircularButtonProps) {
  return (
    <Button
      onClick={onClick || undefined}
      disabled={disabled}
      type={type}
      sx={{
        textTransform: 'none',
        backgroundColor: color,
        color: textColor,
        fontWeight: 600,
        fontSize: fontSize,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        borderRadius: 20,
        '&:hover': {
          backgroundColor: color,
        },
        //disabled text color
        '&.Mui-disabled': {
          color: '#fff'
        },
        ...sx
      }}
    >
      {preIcon}
      {label}
      {postIcon}
    </Button>
  )
}

import { Box, Typography } from '@mui/material'
import React from 'react'

export default function BlogCategories() {

  const categories = [
    "Bütçeleme ve Harcama Yönetimi",
    "Kredi ve Borç Yönetimi",
    "Yatırım ve Portföy Yönetimi",
    "Emeklilik ve Gelecek Planlama",
    "Sigorta ve Risk Yönetimi",
    "Finansal Eğitim ve Danışmanlık",
    "Vergi Planlama ve Mali Danışmanlık",
    "Finansal Teknoloji (Fintech) ve Dijital Finans",
  ]

  return (
    <Box
      sx={{
        borderRadius: 2,
        background: '#F9FAFB',
        px: 4,
        py: 6,
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 700,
          lineHeight: '24px',
        }}
      >
        Kategoriler
      </Typography>
      <Box>
        {categories.map((category, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '24px',
              color: '#3D3D3D',
              my: 2,
              cursor: 'pointer',
              '&:hover': {
                color: '#8377FB',
              },
            }}
          >
            {category}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

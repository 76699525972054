import { Typography } from '@mui/material'
import logo from '../assets/pionr-logo.png'

export default function FooterCopyRight() {
  return (
    <Typography
      sx={{
        textAlign: ['center', 'center', 'left'], // Center text for small screens, left-align for larger screens
        mt: 4,
        display: 'flex',
        alignItems: 'center',

      }}
    >
      Cred bir Pionr
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 8px',
        }}
      >
        <img height={32} src={logo} alt="pionr-logo" />
      </span>
      girişimidir.
    </Typography>
  )
}

import { Box, Typography } from '@mui/material'
import whoIsUs from '../assets/who-is-us.png'
import purpose from '../assets/purpose.png'
import React from 'react'

export default function Row2() {
  return (
    <Box
      sx={{
        width: '100%',
        background: '#F3F5F780'
      }}
    >
      <Box
        sx={{
          maxWidth: 1024,
          width: '100%',
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 1600,
            width: '100%',
            position: 'relative',
            mx: 'auto',
            py: 10,
            gap: 10,
          }}
        >
          <img
            src={whoIsUs}
            alt="who-is-us"
            style={{
              display: 'block',
              margin: 'auto',
              width: '100%',
              maxWidth: 324,
            }}
          />
          <Box
            sx={{
              mx: 2
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 40,
                lineHeight: '49px',
              }}
            >
              Biz Kimiz?
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 400,
                lineHeight: '30px',
                mt: 3,
              }}
            >
              Cred, finansal deneyiminizi kişisel ve anlamlı hale getiren bir aile olarak varlığını sürdürmektedir. Biz sadece bir platform değil, aynı zamanda bir arkadaş, bir rehber ve bir destek kaynağıyız. Misyonumuz, kullanıcılarımızın finansal yolculuğunu daha güvenilir bir şekilde yapmalarını sağlamak ve hedeflerinize ulaşmanıza yardımcı olarak sizi yönlendirmektir.
              <br />
              Cred ekibi, finansal dünyanın içinden gelen bir dizi farklı hikayeden ilham alır. Bu çeşitliliği ve birliği kutlamak için buradayız ve finansın karmaşıklığını sadelikle birleştirme tutkusunu paylaşırız.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 1600,
            width: '100%',
            position: 'relative',
            mx: 'auto',
            py: 10,
            gap: 10,
          }}
        >
          <Box
            sx={{
              mx: 2
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 40,
                lineHeight: '49px',
              }}
            >
              Amacımız
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 400,
                lineHeight: '30px',
                mt: 3,
              }}
            >
              Cred olarak amacımız, finansal özgürlüğe doğru attığınız her adımda size eşlik etmektir. Cred ailesi olarak, her bireyin benzersiz olduğuna inanıyoruz ve bu nedenle kredi deneyimini tamamen kişisel ihtiyaçlarınıza ve hayallerinize uygun hale getiriyoruz. İstediğiniz yaşamı inşa etmenize ve hayallerinizi gerçekleştirmenize yardımcı olacak araçları size sunmak bizim en büyük amacımızdır.
            </Typography>
          </Box>
          <img
            src={purpose}
            alt="who-is-us"
            style={{
              display: 'block',
              margin: 'auto',
              width: '100%',
              maxWidth: 324,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
